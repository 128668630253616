import axios from 'axios';
import config from '@config';
import store from '../state/store';

axios.defaults.baseURL = config.apiUrl;
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
    response => {
        const { headers } = response;
        const key = 'cmsversion';
        const currentVersion = store.getters['versions/getCmsVersion'];
        const newVersion = headers[key];

        if (newVersion && (!currentVersion || currentVersion !== newVersion)) {
            store.commit('versions/SET_CMS_VERSION', newVersion);
        }

        if (newVersion !== currentVersion && newVersion && currentVersion) {
            store.commit('versions/SET_SHOULD_UPDATE', true);
        }

        return response;
    },
    error => {
        const { responseURL } = error.response.request;
        const fromUrl = responseURL.split('/').pop();
        const nonRedirectUrls = ['login', 'forgot-password', 'reset-password', 'logout'];

        const { pathname } = window.location;

        console.warn('Error status', error.response.status);

        if (
            error.response.status === 401
            && !nonRedirectUrls.includes(fromUrl)
            && pathname !== '/'
        ) {
            window.location.href = config.publicPath + `login?redirectFrom=${encodeURI(
                pathname
            )}`;
            localStorage.clear();
        }

        throw error;
    }
);

export default axios;
