import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index(
        vuexContext,
        {
            currentPage = 1,
            perPage = 100,
            search = '',
            sortBy = 'created_at',
            descending = true,
            filters = null,
            colors = null
        } = {}
    ) {
        const { data } = await axios.get('/api/products', {
            params: {
                page: currentPage,
                perPage,
                q: search,
                sortBy,
                order: descending ? 'DESC' : 'ASC',
                filters: JSON.stringify(filters),
                colors
            }
        });

        return data;
    },

    async show(vuexContext, id) {
        const { data } = await axios.get(`/api/products/${id}`);

        return data;
    },

    async store(vuexContext, { product }) {
        const { data } = await axios.post(`/api/products`, product);

        return data;
    },

    async update(vuexContext, { id, product }) {
        const { data } = await axios.post(`/api/products/${id}`, product);

        return data;
    },

    async delete(vuexContext, id) {
        await axios.delete(`/api/products/${id}`);
    },

    async duplicate(vuexContext, id) {
        const { data } = await axios.post(`/api/products/${id}/duplicate`);

        return data;
    },

    async getProductColors(
        vuexContext,
        {
            productId,
            currentPage = 1,
            perPage = 10,
            search = '',
            sortBy = 'created_at',
            descending = true
        }
    ) {
        const { data } = await axios.get(`/api/products/${productId}/colors`, {
            params: {
                page: currentPage,
                perPage,
                q: search,
                sortBy,
                order: descending ? 'DESC' : 'ASC'
            }
        });

        return data;
    },

    async storeProductColor(vuexContext, { productId, color }) {
        const { data } = await axios.post(
            `/api/products/${productId}/colors`,
            color
        );

        return data;
    },

    async findProductColor(vuexContext, id) {
        const { data } = await axios.get(`/api/product-colors/${id}`);

        return data;
    },

    async updateProductColor(vuexContext, { id, color }) {
        const { data } = await axios.put(`/api/product-colors/${id}`, color);

        return data;
    },

    async deleteProductColor(vuexContext, id) {
        await axios.delete(`/api/product-colors/${id}`);
    },

    async changeProductColorsOrder(vuexContext, { productId, elements }) {
        const { data } = await axios.post(
            `/api/products/${productId}/colors/order`,
            {
                elements
            }
        );

        return data;
    },

    async getProductColorNames() {
        const { data } = await axios.get('/api/product-colors/names');

        return data;
    }
};
