<template>
    <div class="left-side-menu">
        <vue-perfect-scrollbar
            v-if="!isMenuCollapsed"
            v-once
            class="slimscroll-menu"
            :settings="settings"
        >
            <side-nav />
        </vue-perfect-scrollbar>
        <side-nav v-else />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import SideNav from './SideNav';

export default {
    components: {
        VuePerfectScrollbar,
        SideNav
    },

    data() {
        return {
            settings: {
                minScrollbarLength: 60
            }
        };
    },

    computed: {
        ...mapGetters({
            isMenuCollapsed: 'layout/isMenuCollapsed'
        })
    },
};
</script>

<style lang="scss">
.slimscroll-menu {
    height: 100%;
}
.ps > .ps__scrollbar-y-rail {
    width: 8px !important;
    background-color: transparent !important;
}
.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
    width: 6px !important;
}
</style>
