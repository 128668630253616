import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index(
        vuexContext,
        {
            currentPage = 1,
            perPage = 10,
            search = '',
            sortBy = 'created_at',
            descending = true,
            filters = null
        } = {}
    ) {
        const { data } = await axios.get('/api/bundles', {
            params: {
                page: currentPage,
                perPage,
                q: search,
                sortBy,
                order: descending ? 'DESC' : 'ASC',
                filters: JSON.stringify(filters)
            }
        });

        return data;
    },

    async show(vuexContext, bundleId) {
        const { data } = await axios.get(`/api/bundles/${bundleId}`);

        return data;
    },

    async store(vuexContext, bundle) {
        const { data } = await axios.post(`/api/bundles`, bundle);

        return data;
    },

    async update(vuexContext, bundle) {
        const { data } = await axios.put(`/api/bundles/${bundle.id}`, bundle);

        return data;
    },

    async delete(vuexContext, id) {
        await axios.delete(`/api/bundles/${id}`);
    }
};
