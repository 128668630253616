<template>
    <div>
        <div class="account-pages mt-5 mb-5">
            <div class="container">
                <slot />
            </div>
        </div>
        <footer class="footer footer-alt">
            {{ currentYear }} © Upstep
        </footer>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    data() {
        return {
            currentYear: moment().format('YYYY')
        };
    },

    created: () => {
        document.body.classList.add('authentication-bg');
        document.body.classList.add('authentication-bg-pattern');
    }
};
</script>
