export const state = {
    HEAD_ADMIN: 'head_admin',
    PERMISSIONS: [
        'users',
        'customers',
        'products',
        'orders',
        'factory_data',
        'logistics_center',
        'batches',
        'warehouses',
        'promo_codes',
        'gift_cards',
        'free_gift',
        'questionnaires',
        'articles',
        'exercises',
        'mailing',
        'media_management',
        'contents',
        'contents_mobile_3d',
        'settings',
        'api_keys',
        'tracking_statuses',
        'timelines',
        'barcode_scanner',
        'designer'
    ]
};

export const getters = {
    getAvailablePermissions: state => state.PERMISSIONS,
    isHeadAdmin: state => roles =>
        roles.some(role => role.name === state.HEAD_ADMIN)
};

export const mutations = {};

export const actions = {};
