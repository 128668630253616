import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index(
        vuexContext,
        {
            currentPage = 1,
            perPage = 10,
            search = '',
            sortBy = 'created_at',
            descending = true,
            filters = null,
            onlyConsistentStatus = false,
            customerId
        }
    ) {
        const params = {
            page: currentPage,
            perPage,
            q: search,
            sortBy,
            order: descending ? 'DESC' : 'ASC',
            onlyConsistentStatus
        };

        if (customerId) {
            params.customerId = customerId;
        }

        if (filters) {
            params.filters = JSON.stringify(filters);
        }

        const { data } = await axios.get('/api/order-product-colors', {
            params
        });

        return data;
    },

    async esIndex(
        vuexContext,
        {
            currentPage = 1,
            perPage = 10,
            search = '',
            sortBy = 'created_at',
            descending = true,
            filters = null,
        }
    ) {
        const params = {
            page: currentPage,
            perPage,
            q: search,
            sortBy,
            order: descending ? 'DESC' : 'ASC',
        };

        if (filters) {
            params.filters = JSON.stringify(filters);
        }

        const { data } = await axios.get('/api/order-product-colors/es', {
            params
        });

        return data;
    },

    async show(vuexContext, id) {
        const { data } = await axios.get(`/api/order-product-colors/${id}`);

        return data;
    },

    async update(vuexContext, orderProductColor) {
        const { data } = await axios.put(
            `/api/order-product-colors/${orderProductColor.id}`,
            orderProductColor
        );

        return data;
    },

    async changeAssignee(vuexContext, { id, userId }) {
        const { data } = await axios.post(
            `/api/order-product-colors/${id}/assignee`,
            {
                userId
            }
        );

        return data;
    },

    async changeStatus(
        vuexContext,
        { id, status, allInOrder, updateScanDate }
    ) {
        const { data } = await axios.post(
            `/api/order-product-colors/${id}/status`,
            {
                status,
                allInOrder,
                updateScanDate
            }
        );

        return data;
    },

    async split(vuexContext, id) {
        const { data } = await axios.post(
            `/api/order-product-colors/${id}/split`
        );

        return data;
    },

    async uploadImage(vuexContext, { id, image, updateStatus }) {
        const imageData = new FormData();
        imageData.append('image', image);

        if (updateStatus) {
            imageData.append('updateStatus', true);
        }

        const { data } = await axios.post(
            `/api/order-product-colors/${id}/images`,
            imageData
        );

        return data;
    },

    async deleteImage(vuexContext, { id, imageId }) {
        return axios.delete(`/api/order-product-colors/${id}/images`, {
            params: {
                imageId
            }
        });
    },

    async createOrUpdateInstruction(vuexContext, { id, body }) {
        const { data } = await axios.post(
            `/api/order-product-colors/${id}/instruction`,
            body
        );

        return data;
    },

    async createShipment(vuexContext, ids) {
        const { data } = await axios.post(
            `/api/order-product-colors/shipment`,
            {
                ids
            }
        );

        return data;
    },

    async bulkChangeStatus(vuexContext, { ids, status }) {
        const { data } = await axios.post(
            `/api/order-product-colors/bulk-status`,
            {
                ids,
                status
            }
        );

        return data;
    },

    async bulkDownloadFiles(vuexContext, ids) {
        const { data } = await axios.post(
            '/api/order-product-colors/bulk-download-files',
            {
                ids
            },
            {
                responseType: 'arraybuffer'
            }
        );

        return data;
    }
};
