import Vue from 'vue';
import '@components/_globals';
import vSelect from 'vue-select';
import Layout from '@layouts/main';
import PageHeader from '@components/PageHeader';
import AwesomeTable from '@components/awesome-table';

Vue.component('VSelect', vSelect);
Vue.component('Layout', Layout);
Vue.component('PageHeader', PageHeader);
Vue.component('AwesomeTable', AwesomeTable);
