import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async show(vuexContext, { key }) {
        const { data } = await axios.get(`/api/content/${key}`);

        return data;
    },

    async update(vuexContext, { key, contentData }) {
        const { data } = await axios.post(`/api/content/${key}`, contentData);

        return data;
    }
};
