const initialState = () => {
    return {
        isMenuCollapsed: false
    };
};

export const state = () => initialState();

export const getters = {
    isMenuCollapsed: state => state.isMenuCollapsed
};

export const mutations = {
    SET_MENU_COLLAPSED: (state, isMenuCollapsed) => {
        state.isMenuCollapsed = isMenuCollapsed;
    }
};

export const actions = {
    async initMenu({ commit, dispatch }) {
        const user = await dispatch('users/getMe', null, { root: true });

        if (user.sidebar_collapsed) {
            document.body.classList.add('enlarged');

            commit('SET_MENU_COLLAPSED', true);
        }
    },

    async toggleMenu({ getters, commit, dispatch }) {
        const isMenuCollapsed = !getters.isMenuCollapsed;

        document.body.classList.toggle('enlarged');

        commit('SET_MENU_COLLAPSED', isMenuCollapsed);

        await dispatch(
            'users/updateMyAccount',
            {
                sidebar_collapsed: isMenuCollapsed
            },
            { root: true }
        );
    }
};
