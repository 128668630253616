<template>
    <div class="filter-box">
        <i v-if="filterSelect" class="fas fa-filter">
            <div class="options">
                <v-select
                    v-model="type"
                    :options="types"
                    :clearable="false"
                    label="text"
                    @input="onTypeChange"
                />
            </div>
        </i>
        <div style="margin: 0 10px; flex: 1">
            <input
                :value="type.array ? value[0] : value"
                type="date"
                class="form-control"
                :placeholder="type.text"
                @input="onValueChange"
            />
            <input
                v-if="type.array"
                :value="value[1] || null"
                type="date"
                class="form-control"
                style="margin-top: 10px"
                :placeholder="type.text"
                @input="onValueChange($event, true)"
            />
        </div>
        <i v-if="clearable && value" class="fas fa-trash" @click="clearValue" />
    </div>
</template>

<script>
import Base from './Base';

export default {
    extends: Base,

    data() {
        return {
            type: {
                text: 'Equals',
                value: 'eq'
            },
            types: [
                {
                    text: 'Equals',
                    value: 'eq'
                },
                {
                    text: 'Is not',
                    value: 'not'
                },
                {
                    text: 'Greater or equal',
                    value: 'gte'
                },
                {
                    text: 'Less or equal',
                    value: 'lte'
                },
                {
                    text: 'Between',
                    value: 'between',
                    array: true
                },
                {
                    text: 'Not Between',
                    value: 'notBetween',
                    array: true
                }
            ]
        };
    },

    methods: {
        onFilterChange(value) {
            const filter = {
                value,
                type: this.type.value
            };

            this.value = value;

            if (this.type.array) {
                if (this.value.filter(item => item).length === 1) {
                    this.$emit('filter-change', {
                        type: this.type.value,
                        value: ''
                    });

                    return;
                }

                if (
                    this.value.filter(item => item).length === 2 &&
                    this.value[0] >= this.value[1]
                ) {
                    return this.$toasterWarning(
                        'Start date has to be greater than end date!'
                    );
                }

                if (!this.value.length) {
                    filter.value = '';
                }
            }

            this.$emit('filter-change', filter);
        },

        onValueChange(event, end = false) {
            const { value } = event.target;

            if (!this.type.array) {
                this.onFilterChange(value);

                return;
            }

            const parsedValue = Array.isArray(this.value)
                ? [...this.value]
                : [this.value];

            parsedValue[end ? 1 : 0] = value;

            this.onFilterChange(parsedValue);
        },

        clearValue() {
            this.onFilterChange(this.type.array ? [] : '');
        },

        onTypeChange() {
            if (this.type.array && !Array.isArray(this.value)) {
                this.value = this.value ? [this.value] : [];
            } else if (!this.type.array && Array.isArray(this.value)) {
                this.value = this.value[0] || '';
            }

            this.onFilterChange(this.value);
        }
    }
};
</script>
