import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index() {
        const { data } = await axios.get(`/api/products/sizes`);

        return data;
    },

    async create(vuexContext, size) {
        const { data } = await axios.post(`/api/products/sizes`, { size });

        return data;
    },

    async update(vuexContext, size) {
        const { data } = await axios.put(`/api/products/sizes/${size.id}`, size);

        return data;
    },

    async delete(vuexContext, sizeId) {
        const { data } = await axios.delete(`/api/products/sizes/${sizeId}`);

        return data;
    },

    async changeOrder(vuexContext, items) {
        const { data } = await axios.post(`/api/products/sizes/order`, {
            items
        });

        return data;
    }
};
