import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index(
        vuexContext,
        {
            currentPage = 1,
            perPage = 10,
            search = '',
            sortBy = 'created_at',
            descending = true
        }
    ) {
        const params = {
            page: currentPage,
            perPage,
            q: search,
            sortBy,
            order: descending ? 'DESC' : 'ASC'
        };

        const { data } = await axios.get(`/api/timelines`, {
            params
        });

        return data;
    },

    async store(vuexContext, data) {
        const { data: timeline } = await axios.post(`/api/timelines`, data);

        return timeline;
    },

    async show(vuexContext, id) {
        const { data } = await axios.get(`/api/timelines/${id}`);

        return data;
    },

    async update(vuexContext, { id, data }) {
        const { data: timeline } = await axios.put(
            `/api/timelines/${id}`,
            data
        );

        return timeline;
    },

    async destroy(vuexContext, id) {
        await axios.delete(`/api/timelines/${id}`);
    }
};
