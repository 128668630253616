import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async cancel(vuexContext, id) {
        const { data } = await axios.post(
            `/api/order-secondary-products/${id}/cancel`
        );

        return data;
    },

    async restore(vuexContext, id) {
        const { data } = await axios.post(
            `/api/order-secondary-products/${id}/restore`
        );

        return data;
    }
};
