import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index() {
        const { data } = await axios.get('/api/exercise-categories');

        return data;
    },

    async show(vuexContext, id) {
        const { data } = await axios.get(`/api/exercise-categories/${id}`);

        return data;
    },

    async store(vuexContext, { name }) {
        const { data } = await axios.post('/api/exercise-categories', {
            name
        });

        return data;
    },

    async update(vuexContext, { id, name }) {
        const { data } = await axios.put(`/api/exercise-categories/${id}`, {
            name
        });

        return data;
    },

    async delete(vuexContext, id) {
        return axios.delete(`/api/exercise-categories/${id}`);
    },

    async changeOrder(vuexContext, items) {
        const { data } = await axios.post('/api/exercise-categories/order', {
            items
        });

        return data;
    }
};
