import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index(
        vuexContext,
        {
            currentPage = 1,
            perPage = 10,
            search = '',
            sortBy = 'created_at',
            descending = true,
            filters = null
        }
    ) {
        const { data } = await axios.get('/api/exercises', {
            params: {
                page: currentPage,
                perPage,
                q: search,
                sortBy,
                order: descending ? 'DESC' : 'ASC',
                filters: JSON.stringify(filters),
                getAll: false
            }
        });

        return data;
    },

    async getAll() {
        const { data } = await axios.get('/api/exercises', {
            params: {
                getAll: true
            }
        });

        return data;
    },

    async show(vuexContext, id) {
        const { data } = await axios.get(`/api/exercises/${id}`);

        return data;
    },

    async store(vuexContext, body) {
        const { data } = await axios.post('/api/exercises', body);

        return data;
    },

    async update(vuexContext, body) {
        const { data } = await axios.put(
            `/api/exercises/${body.id}`,
            body.exercise
        );

        return data;
    },

    async delete(vuexContext, id) {
        return axios.delete(`/api/exercises/${id}`);
    },

    async changeOrder(vuexContext, { subcategoryId, items }) {
        const { data } = await axios.post('/api/exercises/order', {
            subcategoryId,
            items
        });

        return data;
    }
};
