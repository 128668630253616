import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index(
        vuexContext,
        {
            productId,
            currentPage = 1,
            perPage = 10,
            search = '',
            sortBy = 'created_at',
            descending = true
        }
    ) {
        const { data } = await axios.get(
            `/api/products/${productId}/extensions`,
            {
                params: {
                    page: currentPage,
                    perPage,
                    q: search,
                    sortBy,
                    order: descending ? 'DESC' : 'ASC'
                }
            }
        );

        return data;
    },

    async show(vuexContext, id) {
        const { data } = await axios.get(`/api/product-extensions/${id}`);

        return data;
    },

    async store(vuexContext, { productId, extension }) {
        const { data } = await axios.post(
            `/api/products/${productId}/extensions`,
            extension
        );

        return data;
    },

    async update(vuexContext, { id, extension }) {
        const { data } = await axios.put(
            `/api/product-extensions/${id}`,
            extension
        );

        return data;
    },

    async delete(vuexContext, id) {
        await axios.delete(`/api/product-extensions/${id}`);
    },

    async changeOrder(vuexContext, { productId, elements }) {
        const { data } = await axios.post(
            `/api/products/${productId}/extensions/order`,
            {
                elements
            }
        );

        return data;
    }
};
