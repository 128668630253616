<template>
    <div id="app">
        <RouterView :key="$route.fullPath" />

        <version-alert />
    </div>
</template>

<script>
import appConfig from '@src/app.config';
import VersionAlert from '@components/alerts/VersionAlert';

export default {
    page: {
        titleTemplate(title) {
            title = typeof title === 'function' ? title(this.$store) : title;

            return title ? `${title} | ${appConfig.title}` : appConfig.title;
        }
    },

    components: {
        VersionAlert
    }
};
</script>
