import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index(
        vuexContext,
        {
            bundleId,
            currentPage = 1,
            perPage = 10,
            search = '',
            sortBy = 'created_at',
            descending = true
        }
    ) {
        const { data } = await axios.get(`/api/bundles/${bundleId}/colors`, {
            params: {
                page: currentPage,
                perPage,
                q: search,
                sortBy,
                order: descending ? 'DESC' : 'ASC'
            }
        });

        return data;
    },

    async show(vuexContext, id) {
        const { data } = await axios.get(`/api/bundle-colors/${id}`);

        return data;
    },

    async store(vuexContext, { bundleId, color }) {
        const { data } = await axios.post(
            `/api/bundles/${bundleId}/colors`,
            color
        );

        return data;
    },

    async update(vuexContext, { id, color }) {
        const { data } = await axios.put(`/api/bundle-colors/${id}`, color);

        return data;
    },

    async delete(vuexContext, id) {
        await axios.delete(`/api/bundle-colors/${id}`);
    },

    async changeOrder(vuexContext, { bundleId, elements }) {
        const { data } = await axios.post(
            `/api/bundles/${bundleId}/colors/order`,
            { elements }
        );

        return data;
    }
};
