import store from '@state/store';

export default [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('@views/dashboard/Dashboard'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/my-account',
        name: 'myAccount',
        component: () => import('@views/account/MyAccount'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => lazyLoadView(import('@views/auth/Login')),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                if (store.getters['auth/loggedIn']) {
                    next({ name: 'dashboard' });
                } else {
                    next();
                }
            }
        }
    },
    {
        path: '/confirm-account',
        name: 'confirm-account',
        component: () => lazyLoadView(import('@views/auth/Confirm')),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                if (store.getters['auth/loggedIn']) {
                    next({ name: 'dashboard' });
                } else {
                    next();
                }
            }
        }
    },
    {
        path: '/reset-password/:token',
        name: 'reset-password',
        component: () => lazyLoadView(import('@views/auth/ResetPassword')),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                if (store.getters['auth/loggedIn']) {
                    next({ name: 'dashboard' });
                } else {
                    next();
                }
            }
        }
    },
    {
        path: '/forget-password',
        name: 'forget-password',
        component: () => lazyLoadView(import('@views/auth/ForgetPassword')),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                if (store.getters['auth/loggedIn']) {
                    next({ name: 'dashboard' });
                } else {
                    next();
                }
            }
        }
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                store.dispatch('auth/logOut');
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    route => route.meta.authRequired
                );
                next(
                    authRequiredOnPreviousRoute
                        ? { name: 'dashboard' }
                        : { ...routeFrom }
                );
            }
        }
    },
    {
        path: '/404',
        name: '404',
        component: require('@views/pages/_404').default,
        props: true
    },
    {
        path: '*',
        redirect: '404'
    },
    {
        path: '/api-keys',
        name: 'apiKeys',
        component: () => import('@views/api-keys/ApiKeys'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/app-orders',
        name: 'app-orders',
        component: () => import('@views/orders/OrderList'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/articles',
        name: 'articles',
        component: () => import('@views/articles/ArticlesTable'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/articles/add',
        name: 'articlesAdd',
        component: () => import('@views/articles/AddEdit'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/articles/:id/edit',
        name: 'articlesEdit',
        component: () => import('@views/articles/AddEdit'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/attributes',
        name: 'product-attributes',
        component: () => import('@views/products/ProductAttributes'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/barcode-scanner',
        name: 'barcode-scanner',
        component: () => import('@views/barcode-scanner/BarcodeScanner'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/bundles',
        name: 'bundles',
        component: () => import('@views/bundles/BundleList'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/bundles/new',
        name: 'bundle-details-add',
        component: () => import('@views/bundles/BundleDetails'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/bundles/:id/edit',
        name: 'bundle-details',
        component: () => import('@views/bundles/BundleDetails'),
        props: route => ({ bundleId: route.params.id }),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/bundles/:bundleId/colors/new',
        name: 'bundle-color-details-add',
        component: () => import('@views/bundle-colors/BundleColorDetails'),
        props: route => ({
            bundleId: route.params.bundleId
        }),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/bundles/:bundleId/colors/:id/edit',
        name: 'bundle-color-details',
        component: () => import('@views/bundle-colors/BundleColorDetails'),
        props: route => ({
            bundleId: route.params.bundleId,
            id: route.params.id
        }),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/contents/:key',
        name: 'contents',
        component: () => import('@views/contents/Content'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/contents-m/:key',
        name: 'contents-m',
        component: () => import('@views/contents/Content'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/customers',
        name: 'customers',
        component: () => import('@views/customers/CustomersList'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/customers/:customerId/orders',
        name: 'customer-orders',
        component: () => import('@views/orders/OrderList'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/customers/:customerId/factory-data',
        name: 'customer-factory-data',
        component: () => import('@views/factory-data/FactoryData'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/es-factory-data',
        name: 'es-factory-data',
        component: () => import('@views/es-factory-data/EsFactoryData'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/exercises',
        name: 'exercises',
        component: () => import('@views/exercises/ExercisesTable'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/exercises/add',
        name: 'exercisesAdd',
        component: () => import('@views/exercises/AddEdit'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/exercises/:id/edit',
        name: 'exercisesEdit',
        component: () => import('@views/exercises/AddEdit'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/factory-data',
        name: 'factory-data',
        component: () => import('@views/factory-data/FactoryData'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/factory-data/group-instructions',
        name: 'factory-data-group-instructions',
        component: () => import('@views/factory-data/FactoryDataGroupInstructions'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/factory-data/:id',
        name: 'factory-data-details',
        component: () => import('@views/factory-data/FactoryDataDetails'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/factory-data/:id/instructions',
        name: 'factory-data-instructions',
        component: () => import('@views/factory-data/FactoryDataInstructions'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/factory-data/:id/instructions/edit',
        name: 'factory-data-instructions-edit',
        component: () => import('@views/factory-data/FactoryDataInstructionsEdit'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/fedex-batches',
        name: 'fedex-batches',
        component: () => import('@views/fedex-batches/FedexBatches'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/fedex-queues',
        name: 'fedex-queues',
        component: () => import('@views/fedex-queues/FedexQueues'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/free-gift',
        name: 'free-gift',
        component: () => import('@views/free-gift/FreeGift'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/gift-cards',
        name: 'gift-cards',
        component: () => import('@views/gift-cards/GiftCardsList'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/gift-cards/:giftCardId/orders',
        name: 'gift-cards-orders',
        component: () => import('@views/orders/OrderList'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/logistics-center',
        name: 'logistics-center',
        component: () => import('@views/logistics-center/LogisticsCenter'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/logistics-center/:id',
        name: 'logistics-center-details',
        component: () => import('@views/logistics-center/LogisticsCenterDetails'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/mailing',
        name: 'mailing',
        component: () => import('@views/mailing/MailTemplatesList'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/mailing/:id',
        name: 'mailing-details',
        component: () =>
            import('@views/mailing/MailTemplateDetails'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/media',
        name: 'mediaPage',
        component: () => import('@views/media/MediaManagement'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/orders',
        name: 'orders',
        component: () => import('@views/orders/OrderList'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/orders/:id',
        name: 'orders-details',
        component: () => import('@views/orders/OrderDetails'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/products',
        name: 'products',
        component: () => import('@views/products/ProductList'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/products/new',
        name: 'product-details-add',
        component: () => import('@views/products/ProductDetails'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/products/new-secondary',
        name: 'secondary-product-details-add',
        component: () => import('@views/products/SecondaryProductDetails'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/products/:productId/colors/new',
        name: 'product-color-details-add',
        component: () => import('@views/product-colors/Details'),
        props: route => ({
            productId: route.params.productId
        }),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/products/:id/edit',
        name: 'product-details',
        component: () => import('@views/products/ProductDetails'),
        props: route => ({ productId: route.params.id }),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/products/:id/edit-secondary',
        name: 'secondary-product-details',
        component: () => import('@views/products/SecondaryProductDetails'),
        props: route => ({ productId: route.params.id }),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/products/:productId/colors/:id/edit',
        name: 'product-color-details',
        component: () => import('@views/product-colors/Details'),
        props: route => ({
            productId: route.params.productId,
            id: route.params.id
        }),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/products/:productId/extensions/new',
        name: 'product-extension-details-add',
        component: () => import('@views/product-extensions/Details'),
        props: route => ({
            productId: route.params.productId
        }),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/products/:productId/extensions/:id/edit',
        name: 'product-extension-details',
        component: () => import('@views/product-extensions/Details'),
        props: route => ({
            productId: route.params.productId,
            id: route.params.id
        }),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/promo-codes',
        name: 'promo-codes',
        component: () => import('@views/promo-codes/PromoCodesList'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/promo-codes/:codeId/orders',
        name: 'promo-codes-orders',
        component: () => import('@views/orders/OrderList'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/questionnaires',
        name: 'questionnaires',
        component: () =>
            import('@views/questionnaires/QuestionnaireList'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/questionnaires/:questionnaireId/new',
        name: 'questionnaire-new',
        component: () =>
            import('@views/questionnaires/question/QuestionAdd'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/questionnaires/question/:id/edit',
        name: 'questionnaire-edit',
        component: () =>
            import('@views/questionnaires/question/QuestionEdit'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import('@views/settings/Settings'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/timelines',
        name: 'timelines',
        component: () => import('@views/timelines/TimelinesTable'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/timelines/new',
        name: 'addTimeline',
        component: () => import('@views/timelines/AddEdit'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/timelines/:id/edit',
        name: 'editTimeline',
        component: () => import('@views/timelines/AddEdit'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/tracking-statuses',
        name: 'tracking-statuses',
        component: () => import('@views/tracking-statuses/TrackingStatusList'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('@views/users/UserList'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/warehouses',
        name: 'warehouses',
        component: () => import('@views/warehouses/WarehouseList'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/warehouses/add',
        name: 'warehouse-add',
        component: () => import('@views/warehouses/WarehouseDetails'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/warehouses/:id/edit',
        name: 'warehouse-edit',
        component: () => import('@views/warehouses/WarehouseDetails'),
        meta: {
            authRequired: true
        }
    }
];

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
    const AsyncHandler = () => ({
        component: AsyncView,
        // A component to use while the component is loading.
        loading: require('@views/pages/_loading').default,
        // Delay before showing the loading component.
        // Default: 200 (milliseconds).
        delay: 400,
        // A fallback component in case the timeout is exceeded
        // when loading the component.
        error: require('@views/pages/_timeout').default,
        // Time before giving up trying to load the component.
        // Default: Infinity (milliseconds).
        timeout: 10000
    });

    return Promise.resolve({
        functional: true,
        render(h, { data, children }) {
            // Transparently pass any props or children
            // to the view component.
            return h(AsyncHandler, data, children);
        }
    });
}
