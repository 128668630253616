import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index() {
        const { data } = await axios.get('/api/api-keys');

        return data;
    },

    async generateByKey(vuexContext, key) {
        await axios.post(`/api/api-keys/${key}/generate`);
    }
};
