import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    getOrders() {
        return axios.get('/api/dashboard/recent-orders-list');
    },

    getOrdersData(vuexContext, { startDate = '', endDate = '' }) {
        const params = {
            from: startDate,
            to: endDate
        };

        return axios.get('/api/dashboard/orders', { params });
    },

    getSalesData(vuexContext, { startDate = '', endDate = '' }) {
        const params = {
            from: startDate,
            to: endDate
        };

        return axios.get('/api/dashboard/sales', { params });
    },

    getParameters(vuexContext, { startDate = '', endDate = '' }) {
        const params = {
            from: startDate,
            to: endDate
        };

        return axios.get('/api/dashboard/products-parameters', { params });
    },

    getPaymentMethods(vuexContext, { startDate = '', endDate = '' }) {
        const params = {
            from: startDate,
            to: endDate
        };

        return axios.get('/api/dashboard/payment-methods', { params });
    },

    getPercentData(vuexContext, { startDate, endDate }) {
        return axios.get('/api/dashboard/percent-data', {
            params: {
                from: startDate,
                to: endDate
            }
        });
    },

    async getGenderData(vuexContext, { startDate, endDate }) {
        const { data } = await axios.get('/api/dashboard/gender-data', {
            params: {
                from: startDate,
                to: endDate
            }
        });

        return data;
    }
};
