import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index(
        vuexContext,
        {
            currentPage = 1,
            perPage = 10,
            sortBy = 'created_at',
            descending = true,
            onlyArchived = false,
            filters = null
        }
    ) {
        const params = {
            page: currentPage,
            perPage,
            sortBy,
            order: descending ? 'DESC' : 'ASC'
        };

        if (onlyArchived) {
            params.archived = 'true';
        }

        if (filters) {
            params.filters = JSON.stringify(filters);
        }

        const { data } = await axios.get(`/api/codes`, {
            params
        });

        return data;
    },

    async show(vuexContext, id) {
        const { data } = await axios.get(`/api/codes/${id}`);

        return data;
    },

    async store(vuexContext, code) {
        const { data } = await axios.post(`/api/codes`, code);

        return data;
    },

    async update(vuexContext, code) {
        const { data } = await axios.post(`/api/codes/${code.id}`, code);

        return data;
    },

    async delete(vuexContext, code) {
        const { data } = await axios.delete(`/api/codes/${code.id}`);

        return data;
    }
};
