const env = (key, defaultValue = null) => process.env[key] || defaultValue;

const config = {
    env: env('NODE_ENV'),
    url: env('VUE_APP_ADMIN_URL', 'http://127.0.0.1:8080'),
    apiUrl: env('VUE_APP_API_BASE_URL', 'http://127.0.0.1:3001'),
    mediaUrl: env('VUE_APP_MEDIA_URL', 'http://127.0.0.1:3001/media'),
    frontendUrl: env('VUE_APP_FRONTEND_URL', 'http://127.0.0.1:3000'),
    publicPath: env('VUE_APP_PUBLIC_PATH', '/'),
    test: env('VUE_APP_TEST'),
    digitalOceanUrl: env('VUE_APP_DIGITAL_OCEAN_URL'),
    tinymce: env('VUE_APP_TINYMCE'),
};

module.exports = config;
