import Vue from 'vue';
import App from './app';
import router from '@router';
import store from '@state/store';
import config from '@config';

require('./bootstrap/axios.config');
require('./bootstrap/global-components');
require('./bootstrap/global-directives');
require('./bootstrap/global-filters');
require('./bootstrap/global-styles');
require('./bootstrap/plugins');

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = config.env === 'production';

// If running inside Cypress...
if (config.test === 'e2e') {
    // Ensure tests fail when Vue emits an error.
    Vue.config.errorHandler = window.Cypress.cy.onUncaughtException;
}

try {
    window.$ = window.jQuery = require('jquery');
} catch (e) {}

const app = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');

// If running e2e tests...
if (config.test === 'e2e') {
    // Attach the app to the window, which can be useful
    // for manually setting state in Cypress commands
    // such as `cy.logIn()`.
    window.__app__ = app;
}

store.dispatch('settings/setDefaultTimezone');
