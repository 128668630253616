var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"sidebar-menu"}},[_c('ul',{staticClass:"metismenu",attrs:{"id":"side-menu"}},[_vm._l((_vm.items),function(item,itemIndex){return [(item.isMenuTitle)?_c('li',{key:itemIndex,staticClass:"menu-title"},[_vm._v("\n                "+_vm._s(item.text)+"\n            ")]):(_vm.hasUserPermissions(item.permission) && !item.hasSecondaryMenu)?_c('li',{key:itemIndex},[_c('router-link',{attrs:{"to":item.url}},[_c('i',{class:item.icon,style:({
                            'font-size': item.iconSize
                        })}),_c('span',[_vm._v(_vm._s(item.text))])])],1):(_vm.hasUserPermissions(item.permission))?_c('li',{key:itemIndex,staticClass:"has-dropdown",class:{
                    'active mm-active': _vm.isSubActive(item.secondaryItems)
                }},[_c('a',{staticClass:"waves-effect",class:{
                        active: _vm.isSubActive(item.secondaryItems)
                    },attrs:{"aria-expanded":"false"}},[_c('i',{class:item.icon,style:({
                            'font-size': item.iconSize
                        })}),_c('span',[_vm._v(_vm._s(item.text))]),_c('span',{staticClass:"menu-arrow"})]),_c('ul',{staticClass:"nav-second-level",class:{
                        in: _vm.isSubActive(item.secondaryItems) && !_vm.isMenuCollapsed,
                        collapse: _vm.isMobile
                    },attrs:{"aria-expanded":"false"}},[_vm._l((item.secondaryItems),function(subItem,sIndex){return [(!subItem.hasTertiaryMenu)?_c('li',{key:sIndex,class:{ active: _vm.isSubActive(subItem)}},[_c('router-link',{attrs:{"to":subItem.url}},[_vm._v("\n                                "+_vm._s(subItem.text)+"\n                            ")])],1):_c('li',{key:sIndex,class:{ active: _vm.isSubActive(subItem)}},[_c('a',{staticClass:"waves-effect",class:{
                                    active: _vm.isSubActive(subItem)
                                },attrs:{"aria-expanded":"false"}},[_c('i',{staticClass:"fas fa-list"}),_vm._v("\n                                "+_vm._s(subItem.text)+"\n                            ")]),_c('ul',{staticClass:"nav-third-level nav",class:{
                                    in: _vm.isSubActive(subItem)
                                },attrs:{"aria-expanded":"false"}},_vm._l((subItem.tertiaryItems),function(tertiaryItem,tIndex){return _c('li',{key:tIndex,class:{
                                        active: _vm.isSubActive(tertiaryItem)
                                    }},[_c('router-link',{attrs:{"to":tertiaryItem.url}},[_vm._v("\n                                        "+_vm._s(tertiaryItem.text)+"\n                                    ")])],1)}),0)])]})],2)]):_vm._e()]})],2),_c('div',{staticClass:"clearfix"})])}
var staticRenderFns = []

export { render, staticRenderFns }