<template>
    <div class="btn btn-info mr-2" @click="copyToClipboard(url)">
        <i class="far fa-copy" />
    </div>
</template>

<script>
import { url as appUrl } from '@/config';

export default {
    props: {
        filters: {
            type: Object,
            required: false,
            default: null
        },
        pagination: {
            type: Object,
            required: false,
            default: null
        },
        suffix: {
            type: String,
            required: false,
            default: null
        }
    },

    computed: {
        url() {
            let url = `${appUrl}/${this.$route.path.slice(1)}?`;

            if (this.pagination) {
                const {
                    currentPage,
                    perPage,
                    search,
                    sortBy,
                    descending
                } = this.pagination;

                url += `page=${currentPage}&perPage=${perPage}&sortBy=${sortBy}&order=${
                    descending ? 'DESC' : 'ASC'
                }`;

                if (search) {
                    url += `&q=${search}`;
                }
            }

            if (this.filters && Object.keys(this.filters).length) {
                url += `&filters=${JSON.stringify(this.filters)}`;
            }

            if (this.suffix) {
                url += this.suffix;
            }

            return encodeURI(url);
        }
    },

    watch: {
        url: {
            handler() {
                history.replaceState({}, '', this.url);
            },
            immediate: true
        }
    },

    methods: {
        async copyToClipboard(text) {
            await navigator.clipboard.writeText(text);

            this.$toaster('Copied to clipboard!');
        }
    }
};
</script>
