import moment from 'moment';

const dateOnly = function(value) {
    if (value) {
        return moment(value).format('YYYY-MM-DD');
    }

    return 'Indefinitely';
};

export default dateOnly;
