import Vue from 'vue';
import dateOnly from '@filters/dateOnly';
import formatDate from '@filters/formatDate';
import formatDateChat from '@filters/formatDateChat';
import formatDateOnly from '@filters/formatDateOnly';
import variantFixedPrice from '@filters/fixedPrice';
import startCase from '@filters/startCase';
import removeSpecialChars from '@filters/removeSpecialChars';
import removeUnderscore from '@filters/removeUnderscore';
import thousandSeparator from '@filters/thousandSeparator';

Vue.filter('dateOnly', dateOnly);
Vue.filter('formatDate', formatDate);
Vue.filter('formatDateChat', formatDateChat);
Vue.filter('formatDateOnly', formatDateOnly);
Vue.filter('variantFixedPrice', variantFixedPrice);
Vue.filter('startCase', startCase);
Vue.filter('removeSpecialChars', removeSpecialChars);
Vue.filter('removeUnderscore', removeUnderscore);
Vue.filter('thousandSeparator', thousandSeparator);
