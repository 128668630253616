import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index() {
        const { data } = await axios.get('/api/tags');

        return data;
    },

    async store(vuexContext, { name }) {
        const { data } = await axios.post('/api/tags', {
            name
        });

        return data;
    },

    async delete(vuexContext, id) {
        return axios.delete(`/api/tags/${id}`);
    },

    async changeOrder(vuexContext, items) {
        const { data } = await axios.post('/api/tags/order', {
            items
        });

        return data;
    }
};
