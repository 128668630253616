import axios from 'axios';

export const state = {
    currentUser: getSavedState('auth.currentUser')
};

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue;
        saveState('auth.currentUser', newValue);
        setDefaultAuthHeaders(state);
    }
};

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser;
    },
    loggedUser(state) {
        return state.currentUser;
    }
};

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    init({ state, dispatch }) {
        setDefaultAuthHeaders(state);
        dispatch('validate');
    },

    // Logs in the current user.
    logIn({ commit, dispatch, getters }, { email, password } = {}) {
        if (getters.loggedIn) return dispatch('validate');

        return axios
            .post('/api/auth/login', { email, password })
            .then(response => {
                const user = response.data;

                if (user !== undefined) {
                    commit('SET_CURRENT_USER', user);
                }

                return user;
            });
    },

    // Logs out the current user.
    async logOut({ commit }) {
        commit('SET_CURRENT_USER', null);
        await axios.post('/api/auth/logout');
    },

    // register the user
    register(
        { dispatch, getters },
        { fullname, email, password } = {}
    ) {
        if (getters.loggedIn) return dispatch('validate');

        return axios
            .post('/api/register', { fullname, email, password })
            .then(response => {
                const user = response.data;

                return user;
            });
    },

    // register the user
    checkPasswordReset({ dispatch, getters }, { token }) {
        if (getters.loggedIn) return dispatch('validate');

        return axios.get(`/api/auth/reset-password/${token}`);
    },

    sendResetPasswordLink({ dispatch, getters }, { email } = {}) {
        if (getters.loggedIn) return dispatch('validate');

        return axios.post(`/api/auth/forgot-password`, { email });
    },

    resetPassword(
        { dispatch, getters },
        { password, password_confirmation, token } = {}
    ) {
        if (getters.loggedIn) return dispatch('validate');

        return axios.post(`/api/auth/reset-password/${token}`, {
            password,
            password_confirmation
        });
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    validate({ commit, state }) {
        if (!state.currentUser) return Promise.resolve(null);

        return axios
            .get('/api/me')
            .then(response => {
                const user = response.data;
                commit('SET_CURRENT_USER', user);

                return user;
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    commit('SET_CURRENT_USER', null);
                }

                return null;
            });
    }
};

// ===
// Private helpers
// ===

function getSavedState(key) {
    return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state));
}

function setDefaultAuthHeaders(state) {
    axios.defaults.headers.common.Authorization = state.currentUser
        ? state.currentUser.token
        : '';
}
