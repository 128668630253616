import Vue from 'vue';
import config from '@/config';

const mediaUrl = {
    bind(el, binding) {
        if (!binding.value || !binding.value.type) {
            return null;
        }

        const { mediaUrl } = config;
        const {
            date,
            filename,
            extension,
            meta: { breakpoints = [] },
            type
        } = binding.value;

        if (type === 'video') {
            const url = `${mediaUrl}/videos/${date}/${filename}.${extension}`;
            const videoElement = document.createElement('video');
            videoElement.className = 'img-fluid img-thumbnail';
            videoElement.setAttribute('src', url);

            if (binding.modifiers.controls) {
                videoElement.controls = true;
                videoElement.className += ' video';
            }

            el.className = 'media-button';
            el.appendChild(videoElement);

            return;
        }

        let imageUrl = `${mediaUrl}/images/default_image.jpg`;
        el.className = 'media-container';

        if (type === 'image') {
            const breakpointsCopy = [...breakpoints].sort((a, b) => a - b);

            imageUrl = `${mediaUrl}/images/${date}/${filename}${
                breakpointsCopy.length ? `_${breakpointsCopy[0]}` : ''
            }.${extension}`;
        }

        const imgElement = document.createElement('img');
        imgElement.className = 'img-fluid img-thumbnail';
        imgElement.setAttribute('src', imageUrl);

        el.appendChild(imgElement);
    }
};

Vue.directive('media-url', mediaUrl);
