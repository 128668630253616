import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async show(vuexContext, id) {
        const { data } = await axios.get(`/api/questions/${id}`);

        return data;
    },

    async update(vuexContext, question) {
        const { data } = await axios.put(
            `/api/questions/${question.id}`,
            question
        );

        return data;
    },

    async delete(vuexContext, id) {
        const { data } = await axios.delete(`/api/questions/${id}`);

        return data;
    }
};
