import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index(
        vuexContext,
        {
            currentPage = 1,
            perPage = 10,
            search = '',
            sortBy = 'created_at',
            descending = true
        }
    ) {
        const params = {
            page: currentPage,
            perPage,
            q: search,
            sortBy,
            order: descending ? 'DESC' : 'ASC'
        };

        const { data } = await axios.get(`/api/tracking-statuses`, {
            params
        });

        return data;
    },

    async all(vuexContext, isRemake = false) {
        const { data } = await axios.get(`/api/tracking-statuses`, {
            params: { all: true, isRemake }
        });

        return data;
    },

    async store(vuexContext, data) {
        const { data: status } = await axios.post(
            `/api/tracking-statuses`,
            data
        );

        return status;
    },

    async update(vuexContext, { id, data }) {
        const { data: status } = await axios.put(
            `/api/tracking-statuses/${id}`,
            data
        );

        return status;
    },

    async destroy(vuexContext, id) {
        await axios.delete(`/api/tracking-statuses/${id}`);
    }
};
