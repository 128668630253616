import axios from 'axios';

export const state = {
    tableLabels: {}
};

export const getters = {
    getTableLabels: state => state.tableLabels
};

export const mutations = {
    SET_TABLE_LABELS: (state, tableLabels) => {
        state.tableLabels = tableLabels;
    }
};

export const actions = {
    async index(
        vuexContext,
        {
            currentPage = 1,
            perPage = 10,
            search = '',
            sortBy = 'created_at',
            descending = true,
            filters = null
        }
    ) {
        const params = {
            page: currentPage,
            perPage,
            q: search,
            sortBy,
            order: descending ? 'DESC' : 'ASC'
        };

        if (filters) {
            params.filters = JSON.stringify(filters);
        }

        const { data } = await axios.get(`/api/users`, {
            params
        });

        return data;
    },

    async create(vuexContext, user) {
        const { data } = await axios.post(`/api/users`, user);

        return data;
    },

    async update(vuexContext, user) {
        const { data } = await axios.post(`/api/users/${user.id}`, user);

        return data;
    },

    async delete(vuexContext, user) {
        const { data } = await axios.delete(`/api/users/${user.id}`);

        return data;
    },

    async getMe({ commit }) {
        const { data } = await axios.get(`/api/me`);

        commit('SET_TABLE_LABELS', data.table_labels);

        return data;
    },

    async updateMyAccount(vuexContext, userData) {
        const { data } = await axios.post(`/api/profile`, userData);

        return data;
    },

    async updateMyPassword(vuexContext, passwords) {
        const { data } = await axios.post(`/api/profile`, passwords);

        return data;
    }
};
