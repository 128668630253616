import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index(
        vuexContext,
        {
            currentPage = 1,
            perPage = 10,
            search = '',
            sortBy = 'created_at',
            descending = true,
            filters = null,
            onlyConsistentStatus = false
        }
    ) {
        const params = {
            page: currentPage,
            perPage,
            q: search,
            sortBy,
            order: descending ? 'DESC' : 'ASC',
            onlyConsistentStatus
        };

        if (filters) {
            params.filters = JSON.stringify(filters);
        }

        const { data } = await axios.get('/api/order-product-color-shipments', {
            params
        });

        return data;
    }
};
