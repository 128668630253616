<template>
    <div :id="id" class="awesome-table" :class="{ card: tableOptions.isCard }">
        <div
            :class="{ 'card-body': tableOptions.isCard }"
            :style="{
                'margin-bottom': isLoading && !items.length ? '20px' : '0'
            }"
        >
            <div class="row mb-2 col-12">
                <button
                    v-if="tableOptions.addButton"
                    class="btn btn-info waves-effect waves-light mr-1 mb-1"
                    @click="onAddButtonClick"
                >
                    <i class="fas fa-plus-circle mr-1" />
                    {{ tableOptions.addButtonText }}
                </button>
                <button
                    v-if="tableOptions.drag && items.length > 1"
                    class="btn btn-secondary waves-effect waves-light mr-1 mb-1"
                    @click="onToggleSortButtonClick"
                >
                    Sorting:
                    <span :class="`${showDrag ? 'sorting-on' : 'sorting-off'}`">
                        {{ showDrag ? 'ON' : 'OFF' }}
                    </span>
                </button>
                <slot name="globalActionButtons" />
            </div>

            <div class="row mb-2">
                <form
                    v-if="tableOptions.searchInput"
                    class="col-12 col-xl-6 form-group d-flex align-items-end"
                    @submit.prevent="onSearch"
                >
                    <input
                        v-model="tablePagination.search"
                        type="text"
                        class="form-control"
                        dir="auto"
                        placeholder="Searching for..."
                    />
                    <button
                        class="btn btn-danger waves-effect waves-light ml-2"
                    >
                        <i class="fas fa-search" />
                    </button>
                </form>
                <div v-else class="col-12 col-xl-6" />

                <pagination
                    v-if="tableOptions.pagination && tablePagination.total"
                    class="col-12 col-xl-6"
                    :current-page="tablePagination.currentPage"
                    :total="tablePagination.total"
                    :per-page="tablePagination.perPage"
                    @set-page="setPage"
                    @set-per-page="onPerPageChange"
                />
            </div>
            <div class="row mb-2">
                <div class="col-12 d-flex justify-content-end">
                    <columns-selector
                        v-if="tableOptions.columnsSelector"
                        :labels="tableLabels"
                        @toggle-display="onToggleDisplay"
                    />
                    <copy-link
                        v-if="tableOptions.link"
                        :filters="tableOptions.filters ? filters : null"
                        :pagination="
                            tableOptions.pagination ? tablePagination : null
                        "
                        :suffix="tableOptions.linkSuffix"
                    />
                    <applied-filters
                        v-if="tableOptions.filters"
                        :filters="appliedFilters"
                    />
                </div>
            </div>
            <div
                v-if="tableOptions.select && selected.length"
                class="alert alert-info d-flex justify-content-between align-items-center"
                role="alert"
            >
                <div>
                    <strong> Selected: {{ selected.length }} </strong>
                </div>
                <div>
                    <slot name="selectButtons" />
                    <csv-export
                        v-if="tableOptions.csvExport"
                        :items="items"
                        :selected="selected"
                        :labels="tableLabels"
                        :table-options="tableOptions"
                    />
                    <button
                        class="btn btn-danger waves-effect waves-light mr-1"
                        type="button"
                        @click="selected = []"
                    >
                        <i class="fas fa-times-circle mr-1" />
                        Clear all
                    </button>
                </div>
            </div>

            <div class="scroll-bar">
                <div class="inner" />
            </div>
            <div class="table-responsive">
                <table
                    class="table table-centered text-center"
                    :class="tableOptions.tableClass"
                    :style="tableOptions.tableStyle"
                >
                    <thead class="thead-light">
                        <tr>
                            <th v-if="showDrag" />
                            <th v-if="tableOptions.select && items.length">
                                <b-form-checkbox
                                    :checked="isAnyItemOnPageSelected"
                                    :indeterminate="
                                        isAnyItemOnPageSelected &&
                                            !isEveryItemOnPageSelected
                                    "
                                    class="checkbox-blue"
                                    @change="toggleAllOnPageSelected"
                                />
                            </th>
                            <th
                                v-for="(label, index) in displayableLabels"
                                :key="index + '_head'"
                                :class="{
                                    'sortable-label':
                                        tableOptions.sort &&
                                        label.sortable !== false
                                }"
                                :style="labelStyle(label)"
                                @click="onLabelClick(label)"
                            >
                                <template
                                    v-if="
                                        tableOptions.sort &&
                                            label.sortable !== false &&
                                            isCurrentSort(label)
                                    "
                                >
                                    <i
                                        v-if="tablePagination.descending"
                                        class="fas fa-arrow-down"
                                    />
                                    <i v-else class="fas fa-arrow-up" />
                                </template>
                                <slot
                                    :name="`labels.${label.value || label}`"
                                    :label="getLabel(label)"
                                >
                                    {{ getLabel(label) }}
                                </slot>
                            </th>
                        </tr>
                    </thead>
                    <filters
                        v-if="tableOptions.filters"
                        :labels="displayableLabels"
                        :init-filters="filters"
                        :empty-cells-before="emptyCellsBeforeFiltersCount"
                        @filter-change="onFilterChange"
                    />
                    <div v-if="isLoading" class="awesome-table-loader">
                        <div class="awesome-spinner">
                            <div class="dot1" />
                            <div class="dot2" />
                        </div>
                    </div>
                    <draggable
                        v-if="items.length"
                        :key="dragKey"
                        tag="tbody"
                        :list="items"
                        group="people"
                        handle=".handle"
                        :style="{ opacity: isLoading ? '0.3' : '1' }"
                        @start="drag = true"
                        @end="onDragEnd"
                    >
                        <tr
                            v-for="(item, trIndex) in items"
                            :key="trIndex + '_body'"
                            :class="
                                `${
                                    tableOptions.clickableRows
                                        ? 'clickable-row'
                                        : ''
                                } ${getRowClass(item, trIndex)}`
                            "
                            @click="onRowClick(item, trIndex)"
                            @mouseup="onMouseUp($event, item, trIndex)"
                        >
                            <td v-if="showDrag" @click.stop>
                                <button
                                    type="button"
                                    class="handle btn btn-secondary waves-effect waves-light mr-2"
                                    style="cursor: move"
                                >
                                    <i class="fas fa-arrows-alt" />
                                </button>
                            </td>
                            <td
                                v-if="tableOptions.select"
                                style="cursor: default"
                                @click.stop
                            >
                                <b-form-checkbox
                                    :checked="isSelected(item)"
                                    class="checkbox-blue"
                                    @change="toggleSelected(item)"
                                />
                            </td>
                            <td
                                v-for="(label, tdIndex) in displayableLabels"
                                :key="trIndex + '_body' + tdIndex"
                                :ref="`td_${trIndex}_${tdIndex}`"
                            >
                                <slot
                                    :name="`items.${label.value || label}`"
                                    :value="getValue(item, label)"
                                    :item="{ ...item }"
                                    :index="trIndex"
                                >
                                    <span
                                        v-if="
                                            label.type === 'boolean' &&
                                                label.isBadge
                                        "
                                    >
                                        <span
                                            v-if="
                                                getValue(item, label) ===
                                                    tableOptions.emptyRow
                                            "
                                            :class="
                                                getBadgeClass(
                                                    label.badgeOptions,
                                                    'falseVariant'
                                                )
                                            "
                                        >
                                            NO
                                        </span>
                                        <span
                                            v-else
                                            :class="
                                                getBadgeClass(
                                                    label.badgeOptions,
                                                    'trueVariant'
                                                )
                                            "
                                        >
                                            YES
                                        </span>
                                    </span>
                                    <span v-else dir="auto">
                                        {{ getValue(item, label) }}
                                    </span>
                                </slot>
                            </td>
                        </tr>
                    </draggable>

                    <sum-section
                        :items="items"
                        :labels="displayableLabels"
                        :get-value="getValue"
                        :empty-cells-before="emptyCellsBeforeFiltersCount"
                    />
                </table>

                <b-alert
                    v-if="!isLoading && !items.length"
                    class="text-center"
                    show
                >
                    {{ tableOptions.noDataText }}
                </b-alert>
            </div>

            <pagination
                v-if="tableOptions.pagination && tablePagination.total"
                :current-page="tablePagination.currentPage"
                :total="tablePagination.total"
                :per-page="tablePagination.perPage"
                @set-page="setPage"
                @set-per-page="onPerPageChange"
            />
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Draggable from 'vuedraggable';
import Filters from './Filters';
import CopyLink from './CopyLink';
import CsvExport from './CsvExport';
import SumSection from './SumSection';
import Pagination from './Pagination';
import ColumnsSelector from './ColumnsSelector';
import getLabelHelper from './helpers/get-label';
import getValueHelper from './helpers/get-value';
import AppliedFilters from './filters/AppliedFilters';

export default {
    components: {
        ColumnsSelector,
        AppliedFilters,
        SumSection,
        Pagination,
        Draggable,
        CsvExport,
        CopyLink,
        Filters
    },

    props: {
        id: {
            type: String,
            required: false,
            default: 'awesome-table'
        },
        labels: {
            type: Array,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        options: {
            type: Object,
            required: false,
            default: () => ({})
        },
        pagination: {
            type: Object,
            required: false,
            default: () => ({})
        },
        getRowClass: {
            type: Function,
            required: false,
            default: () => ''
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        filters: {
            type: Object,
            required: false,
            default: null
        },
        deletedItemId: {
            type: String,
            required: false,
            default: ''
        }
    },

    data() {
        return {
            tableLabels: [],
            defaultOptions: {
                addButton: true,
                addButtonText: 'Add new',
                emptyRow: '-',
                searchInput: true,
                pagination: true,
                clickableRows: true,
                sort: true,
                select: false,
                selectField: 'id',
                drag: false,
                filters: false,
                link: true,
                linkSuffix: null,
                topScroll: false,
                noDataText: 'No data found',
                csvExport: true,
                columnsSelector: false,
                isCard: true,
                tableClass: '',
                tableStyle: ''
            },
            defaultPagination: {
                currentPage: 1,
                perPage: 10,
                search: '',
                total: 1,
                sortBy: 'created_at',
                descending: true
            },
            selected: [],
            drag: false,
            dragKey: 0,
            appliedFilters: {}
        };
    },

    computed: {
        ...mapGetters({
            userLabels: 'users/getTableLabels'
        }),
        tableOptions() {
            return { ...this.defaultOptions, ...this.options };
        },
        tablePagination() {
            return { ...this.defaultPagination, ...this.pagination };
        },
        isEveryItemOnPageSelected() {
            return this.items.every(item => {
                return this.selected.includes(
                    item[this.tableOptions.selectField]
                );
            });
        },
        isAnyItemOnPageSelected() {
            return this.items.some(item => {
                return this.selected.includes(
                    item[this.tableOptions.selectField]
                );
            });
        },
        showDrag() {
            if (!this.tableOptions.drag) {
                return false;
            }

            if (
                this.tablePagination.sortBy !== 'order' ||
                this.tablePagination.descending
            ) {
                return false;
            }

            return this.items.length >= 2;
        },
        emptyCellsBeforeFiltersCount() {
            let count = 0;

            if (this.showDrag) {
                count += 1;
            }

            if (this.tableOptions.select && this.items.length) {
                count += 1;
            }

            return count;
        },
        displayableLabels() {
            return this.tableLabels.filter(label => !label.isHidden);
        }
    },

    watch: {
        drag() {
            if (!this.drag) {
                this.dragKey += 1;
            }
        },
        items: {
            handler() {
                if (this.options.topScroll) {
                    this.onTableWidthChange();
                }
            },
            immediate: true
        },
        deletedItemId() {
            if (this.deletedItemId) {
                this.checkDeletedInSelected();
            }
        },
        userLabels() {
            this.setUserLabels();
        }
    },

    created() {
        this.tableLabels = _.cloneDeep(this.labels);

        if (this.tableOptions.columnsSelector) {
            this.tableLabels = this.tableLabels.map(label => {
                if (typeof label === 'string') {
                    label = {
                        value: label
                    };
                }

                return {
                    ...label,
                    isHidden: false
                };
            });
        }

        if (this.filters) {
            this.getAppliedFilters(this.filters);
        }

        if (this.tableOptions.selectField.includes('.')) {
            console.warn(
                '[WARNING] Select field should not be a value from the subobject!'
            );
        }

        this.setUserLabels();
    },

    methods: {
        ...mapActions({
            updateMyAccount: 'users/updateMyAccount'
        }),

        onLabelClick(label) {
            if (!this.tableOptions.sort || label.sortable === false) {
                return;
            }

            this.changePagination({
                descending: this.isCurrentSort(label)
                    ? !this.tablePagination.descending
                    : true,
                sortBy: this.getSortingValue(label),
                currentPage: 1
            });
        },

        getLabel(label) {
            return getLabelHelper(label);
        },

        labelStyle(label) {
            const styles = {
                cursor:
                    this.tableOptions.sort && label.sortable !== false
                        ? 'pointer'
                        : 'default',
                'background-color':
                    this.tableOptions.sort && this.isCurrentSort(label)
                        ? '#f1f5f7'
                        : '',
                'min-width': label.width || ''
            };

            return styles;
        },

        setUserLabels() {
            const userLabels = this.userLabels[this.id];

            if (!userLabels) {
                return;
            }

            this.tableLabels = this.tableLabels.map(tableLabel => {
                const userLabel = userLabels.find(
                    userLabel => userLabel.value === tableLabel.value
                );

                return {
                    ...tableLabel,
                    isHidden: userLabel ? userLabel.isHidden : tableLabel.isHidden
                };
            });
        },

        isCurrentSort(label) {
            return this.tablePagination.sortBy === this.getSortingValue(label);
        },

        getSortingValue(label) {
            let sortingValue = '';

            if (label.sortFieldName) {
                sortingValue = label.sortFieldName;
            } else if (label.value) {
                sortingValue = label.value;
            } else {
                sortingValue = label;
            }

            return sortingValue;
        },

        getValue(item, label) {
            return getValueHelper(
                item,
                label,
                this.tableOptions,
                this.$options.filters
            );
        },

        onAddButtonClick() {
            this.$emit('add-button-click');
        },

        onRowClick(item, trIndex) {
            this.$emit('row-click', item, trIndex);
        },

        onMouseUp(event, item, trIndex) {
            if (event.button === 1) {
                this.$emit('mouse-wheel-click', item, trIndex);
            }
        },

        setPage(page) {
            this.changePagination({ currentPage: page });
        },

        onPerPageChange(perPage) {
            this.changePagination({ perPage, currentPage: 1 });
        },

        changePagination(pagination) {
            this.$emit('pagination-change', {
                ...this.tablePagination,
                ...pagination
            });
        },

        isSelected(item) {
            return !!this.selected.includes(
                item[this.tableOptions.selectField]
            );
        },

        toggleSelected(item) {
            if (!this.isSelected(item)) {
                this.selected = [
                    ...this.selected,
                    item[this.tableOptions.selectField]
                ];
            } else {
                const index = this.selected.indexOf(
                    item[this.tableOptions.selectField]
                );

                this.selected.splice(index, 1);
            }

            this.$emit('selection-change', this.selected);
        },

        toggleAllOnPageSelected() {
            if (this.isAnyItemOnPageSelected) {
                this.items.forEach(item => {
                    const index = this.selected.indexOf(
                        item[this.tableOptions.selectField]
                    );

                    if (index !== -1) {
                        this.selected.splice(index, 1);
                    }
                });
            } else {
                this.items.forEach(item => {
                    const isIncluded = this.selected.includes(
                        item[this.tableOptions.selectField]
                    );

                    if (!isIncluded) {
                        this.selected = [
                            ...this.selected,
                            item[this.tableOptions.selectField]
                        ];
                    }
                });
            }

            this.$emit('selection-change', this.selected);
        },

        unselect(unselected = null) {
            if (!unselected) {
                this.selected = [];
            } else if (Array.isArray(unselected)) {
                this.selected = this.selected.filter(
                    i => !unselected.includes(i)
                );
            } else {
                const index = this.selected.indexOf(unselected);

                this.selected.splice(index, 1);
            }

            this.$emit('selection-change', this.selected);
        },

        onDragEnd() {
            this.drag = false;

            const minOrderElement = [...this.items].sort(
                (a, b) => a.order - b.order
            )[0];

            const minOrder = minOrderElement.order;

            const elements = this.items.map((item, index) => ({
                id: item.id,
                order: minOrder + index
            }));

            this.$emit('elements-moved', elements);
        },

        onToggleSortButtonClick() {
            if (this.showDrag) {
                this.changePagination({
                    sortBy: 'created_at',
                    descending: true
                });
            } else {
                this.changePagination({
                    sortBy: 'order',
                    descending: false
                });
            }
        },

        onFilterChange(filters) {
            this.$emit('filter-change', filters);

            if (this.tablePagination.currentPage !== 1) {
                this.setPage(1);
            }

            this.getAppliedFilters(filters);
        },

        onSearch() {
            const { search } = this.tablePagination;

            this.changePagination({ search, currentPage: 1 });
            this.$emit('search', search);
        },

        getAppliedFilters(filters) {
            this.appliedFilters = _.cloneDeep(filters);

            for (const key in this.appliedFilters) {
                const label = this.tableLabels.find(label =>
                    [label.filterFieldName, label.value, label].includes(key)
                );

                if (!label) {
                    delete this.appliedFilters[key];

                    continue;
                }

                this.appliedFilters[key].text =
                    label.text || label.value || label;
                this.appliedFilters[key].fieldType = label.type || 'string';
            }
        },

        async onTableWidthChange() {
            await this.$nextTick();

            const awesomeTable = document.querySelector(
                '.awesome-table .table-responsive .table'
            );

            const innerScroll = document.querySelector(
                '.awesome-table .scroll-bar .inner'
            );

            if (!awesomeTable || !innerScroll) {
                return;
            }

            innerScroll.style.width = `${awesomeTable.scrollWidth}px`;

            const tableContainer = document.querySelector(
                '.awesome-table .table-responsive'
            );
            const outerScroll = document.querySelector(
                '.awesome-table .scroll-bar'
            );

            tableContainer.onscroll = () => {
                outerScroll.scrollLeft = tableContainer.scrollLeft;
            };

            outerScroll.onscroll = () => {
                tableContainer.scrollLeft = outerScroll.scrollLeft;
            };
        },

        onToggleDisplay(index) {
            const label = this.tableLabels[index];

            this.tableLabels.splice(index, 1, {
                ...label,
                isHidden: !label.isHidden
            });

            this.updateUserLabels();
        },

        async updateUserLabels() {
            if (this.id === 'awesome-table') {
                return;
            }

            const tableLabels = {
                ...this.userLabels
            };

            tableLabels[this.id] = this.tableLabels.map(label => ({
                value: label.value,
                isHidden: label.isHidden
            }));

            await this.updateMyAccount({
                table_labels: tableLabels
            });
        },

        getBadgeClass(badgeOptions, variant) {
            let variantClass = variant === 'trueVariant' ? 'success' : 'danger';

            if (badgeOptions && badgeOptions[variant]) {
                variantClass = badgeOptions[variant];
            }

            return `badge bg-soft-${variantClass} text-${variantClass}`;
        },

        checkDeletedInSelected() {
            if (!this.selected || !this.selected.length) {
                return;
            }

            const deletedItemIndex = this.selected.findIndex(
                id => id === this.deletedItemId
            );

            if (~deletedItemIndex) {
                this.selected.splice(deletedItemIndex, 1);
            }
        }
    }
};
</script>
