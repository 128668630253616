import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async store(vuexContext, { bundleId, bundleProduct }) {
        const { data } = await axios.post(
            `/api/bundles/${bundleId}/products`,
            bundleProduct
        );

        return data;
    },

    async update(vuexContext, bundleProduct) {
        const { data } = await axios.put(
            `/api/bundle-products/${bundleProduct.id}`,
            bundleProduct
        );

        return data;
    },

    async delete(vuexContext, id) {
        await axios.delete(`/api/bundle-products/${id}`);
    }
};
