<template>
    <div>
        <v-select
            v-model="value"
            class="enum-filter"
            multiple
            label="text"
            :options="options"
            :reduce="
                option => (option.value !== undefined ? option.value : option)
            "
            @input="onFilterChange"
        />
        <i v-if="value.length" class="fas fa-trash" @click="clearValue" />
    </div>
</template>

<script>
export default {
    props: {
        default: {
            type: Object,
            required: false,
            default: null
        },
        options: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            value: []
        };
    },

    created() {
        if (this.default) {
            this.value = this.default.value;
        }
    },

    methods: {
        onFilterChange() {
            const filter = {
                value: this.value.length ? this.value : null,
                type: 'in'
            };

            this.$emit('filter-change', filter);
        },

        clearValue() {
            this.value = [];
            this.onFilterChange();
        }
    }
};
</script>
