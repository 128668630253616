import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index() {
        const { data } = await axios.get('/api/shop-categories');

        return data;
    },

    async show(vuexContext, id) {
        const { data } = await axios.get(`/api/shop-categories/${id}`);

        return data;
    },

    async store(vuexContext, shopCategory) {
        const { data } = await axios.post(`/api/shop-categories`, shopCategory);

        return data;
    },

    async update(vuexContext, shopCategory) {
        const { data } = await axios.put(
            `/api/shop-categories/${shopCategory.id}`,
            shopCategory
        );

        return data;
    },

    delete(vuexContext, id) {
        return axios.delete(`/api/shop-categories/${id}`);
    },

    async changeOrder(vuexContext, items) {
        const { data } = await axios.post('/api/shop-categories/order', {
            items
        });

        return data;
    }
};
