import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index(vuexContext, { page = 1, perPage = 10, searchParams = {} }) {
        const { data } = await axios.get(`/api/media`, {
            params: {
                page,
                perPage,
                ...searchParams
            }
        });

        return data;
    },

    async show(vuexContext, id) {
        const { data } = await axios.get(`/api/media/${id}`);

        return data;
    },

    async store(vuexContext, media) {
        const { data } = await axios.post(`/api/media`, media);

        return data;
    },

    async update(vuexContext, media) {
        const { data } = await axios.put(`/api/media/${media.id}`, media);

        return data;
    },

    async delete(vuexContext, id) {
        await axios.delete(`/api/media/${id}`);
    }
};
