import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import NProgress from 'nprogress/nprogress';
import store from '@state/store';
import routes from './routes';
import config from '@config';

Vue.use(VueRouter);
Vue.use(VueMeta, {
    keyName: 'page'
});

const router = new VueRouter({
    routes,
    base: config.publicPath,
    mode: 'history',
    linkActiveClass: 'active',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

router.beforeEach((routeTo, routeFrom, next) => {
    if (routeFrom.name !== null) {
        NProgress.configure({ showSpinner: false });
        NProgress.start();
    }

    next();

    const authRequired = routeTo.matched.some(route => route.meta.authRequired);

    if (routeTo.name === 'login') {
        next();
    }

    if (!authRequired) return next();

    if (store.getters['auth/loggedIn']) {
        return store.dispatch('auth/validate').then(validUser => {
            validUser ? next() : redirectToLogin();
        });
    }

    redirectToLogin();

    function redirectToLogin() {
        if (routeTo.fullPath !== '/') {
            next({ name: 'login', query: { redirectFrom: routeTo.fullPath } });
        } else {
            next({ name: 'login' });
        }
    }
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
    try {
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        if (args.length) {
                            if (routeFrom.name === args[0].name) {
                                NProgress.done();
                            }

                            next(...args);
                            reject(new Error('Redirected'));
                        } else {
                            resolve();
                        }
                    });
                } else {
                    resolve();
                }
            });
        }
    } catch (error) {
        return;
    }

    next();
});

router.afterEach(() => {
    NProgress.done();
});

export default router;
