import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async show(vuexContext, id) {
        const { data } = await axios.get(`/api/exercise-subcategories/${id}`);

        return data;
    },

    async store(vuexContext, body) {
        const { data } = await axios.post('/api/exercise-subcategories', body);

        return data;
    },

    async update(vuexContext, body) {
        const { data } = await axios.put(
            `/api/exercise-subcategories/${body.id}`,
            body
        );

        return data;
    },

    async delete(vuexContext, id) {
        return axios.delete(`/api/exercise-subcategories/${id}`);
    },

    async changeOrder(vuexContext, items) {
        const { data } = await axios.post('/api/exercise-subcategories/order', {
            items
        });

        return data;
    },

    async addExercise(vuexContext, { subcategoryId, exerciseId, level }) {
        const { data } = await axios.put(
            `/api/exercise-subcategories/${subcategoryId}/add-exercise`,
            {
                exerciseId,
                level
            }
        );

        return data;
    },

    async removeExercise(vuexContext, { subcategoryId, exerciseId }) {
        const { data } = await axios.put(
            `/api/exercise-subcategories/${subcategoryId}/remove-exercise`,
            {
                exerciseId
            }
        );

        return data;
    }
};
