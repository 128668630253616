import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index(
        vuexContext,
        {
            currentPage = 1,
            perPage = 10,
            search = '',
            sortBy = 'created_at',
            descending = true,
            status,
            customerId,
            codeId,
            giftCardId,
            filters = null
        }
    ) {
        const params = {
            page: currentPage,
            perPage,
            q: search,
            sortBy,
            order: descending ? 'DESC' : 'ASC',
            status
        };

        if (customerId) {
            params.customerId = customerId;
        }

        if (codeId) {
            params.codeId = codeId;
        }

        if (giftCardId) {
            params.giftCardId = giftCardId;
        }

        if (filters) {
            params.filters = JSON.stringify(filters);
        }

        const { data } = await axios.get('/api/orders', { params });

        return data;
    },

    async show(vuexContext, id) {
        const { data } = await axios.get(`/api/orders/${id}`);

        return data;
    },

    async update(vuexContext, order) {
        const { data } = await axios.post(`/api/orders/${order.id}`, order);

        return data;
    },

    async getZendeskTickets(vuexContext, id) {
        const { data } = await axios.get(`/api/orders/${id}/zendesk-tickets`);

        return data;
    },

    async changeStatus(vuexContext, { id, status, tracking }) {
        if (status === 'FULFILLED') {
            const { data } = await axios.post(`/api/orders/${id}/fulfilled`, {
                tracking
            });

            return data;
        }

        const { data } = await axios.post(`/api/orders/${id}/status`, {
            status
        });

        return data;
    },

    async changeLogisticsStatus(vuexContext, { id, logisticsStatus }) {
        const { data } = await axios.post(
            `/api/orders/${id}/logistics-status`,
            {
                logisticsStatus
            }
        );

        return data;
    },

    async csvExport(vuexContext, { ids, type, startDate, endDate }) {
        const { data } = await axios.post('/api/orders/csv-export', {
            ids,
            type,
            startDate,
            endDate
        });

        return data;
    },

    async samePersonReportExport(vuexContext, ids) {
        const { data } = await axios.post(
            '/api/orders/same-person-report-export',
            {
                ids
            }
        );

        return data;
    },

    async dailyReportExport(vuexContext, { startDate, endDate }) {
        const { data } = await axios.post('/api/orders/daily-report-export', {
            startDate,
            endDate
        });

        return data;
    },

    async quantityReportExport(vuexContext, { startDate, endDate }) {
        const { data } = await axios.post(
            '/api/orders/quantity-report-export',
            {
                startDate,
                endDate
            }
        );

        return data;
    },

    async productsPriceReportExport(vuexContext, { startDate, endDate }) {
        const { data } = await axios.post(
            '/api/orders/products-price-report-export',
            {
                startDate,
                endDate
            }
        );

        return data;
    },

    async retentionReportExport(vuexContext, { startDate, endDate }) {
        const { data } = await axios.post(
            '/api/orders/retention-report-export',
            {
                startDate,
                endDate
            }
        );

        return data;
    },

    async klaviyoSync(vuexContext, file) {
        const form = new FormData();
        form.append('file', file);

        const { data } = await axios.post('/api/klaviyo/csv-sync', form);

        return data;
    },

    async generateDetailedInvoice(vuexContext, { id, refresh }) {
        const { data } = await axios.post(
            `/api/orders/${id}/generate-detailed-invoice`,
            { refresh }
        );

        return data;
    }
};
