import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index(vuexContext, { postPurchaseOnly, prePurchaseOnly } = {}) {
        const { data } = await axios.get('/api/questionnaires', {
            params: {
                postPurchaseOnly,
                prePurchaseOnly
            }
        });

        return data;
    },

    async store(vuexContext, questionnaire) {
        const { data } = await axios.post('/api/questionnaires', questionnaire);

        return data;
    },

    async update(vuexContext, questionnaire) {
        const { data } = await axios.put(
            `/api/questionnaires/${questionnaire.id}`,
            questionnaire
        );

        return data;
    },

    async delete(vuexContext, id) {
        await axios.delete(`/api/questionnaires/${id}`);
    },

    async getQuestions(vuexContext, questionnaireId) {
        const { data } = await axios.get(
            `/api/questionnaires/${questionnaireId}/questions`
        );

        return data;
    },

    async storeQuestion(vuexContext, { questionData, questionnaireId }) {
        const { data } = await axios.post(
            `/api/questionnaires/${questionnaireId}/questions`,
            questionData
        );

        return data;
    },

    async getAvailableSubquestions(
        vuexContext,
        { questionnaireId, questionId }
    ) {
        const { data } = await axios.get(
            `/api/questionnaires/${questionnaireId}/questions/${questionId}/available-subquestion`
        );

        return data;
    },

    async changeQuestionsOrder(
        vuexContext,
        { questionnaireId, orderedQuestions }
    ) {
        await axios.post(
            `/api/questionnaires/${questionnaireId}/questions/order`,
            {
                items: orderedQuestions
            }
        );
    },

    async toggleProgressBar(vuexContext, questionnaire) {
        const { data } = await axios.put(
            `/api/questionnaires/${questionnaire.id}/toggle-progress-bar`,
            questionnaire
        );

        return data;
    }
};
