import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index() {
        const { data } = await axios.get(`/api/upsell-products`);

        return data;
    },

    async store(vuexContext, { productId, order, column }) {
        const { data } = await axios.post(`/api/upsell-products`, {
            productId,
            order,
            column
        });

        return data;
    },

    delete(vuexContext, id) {
        return axios.delete(`/api/upsell-products/${id}`);
    },

    async changeOrder(vuexContext, items) {
        const { data } = await axios.put(`/api/upsell-products/order`, {
            items
        });

        return data;
    }
};
