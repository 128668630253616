<template>
    <div class="columns-selector">
        <div class="btn show-columns-selector-button mr-2">
            <i class="fas fa-columns" />
        </div>
        <div class="labels-list card">
            <div class="card-body">
                <table
                    v-for="(chunk, chunkIndex) in labelsChunks"
                    id="products-parameters-datatable"
                    :key="chunkIndex"
                    class="table table-centered table table-borderless table-sm mb-0"
                >
                    <tr
                        v-for="(label, labelIndex) in chunk"
                        :key="labelIndex"
                        @click.prevent="onInputClick(chunkIndex, labelIndex)"
                    >
                        <td>
                            <label :for="`label_${label.value}`" class="mb-0">
                                {{ getLabel(label) }}
                            </label>
                        </td>
                        <td>
                            <b-form-checkbox
                                :id="`label_${label.value}`"
                                :checked="!label.isHidden"
                                class="text-muted checkbox-blue"
                            />
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import getLabelHelper from './helpers/get-label';
import chunk from 'lodash/chunk';

export default {
    props: {
        labels: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            chunkSize: 15
        }
    },

    computed: {
        labelsChunks() {
            return chunk(this.labels, this.chunkSize)
        }
    },

    methods: {
        getLabel(label) {
            return getLabelHelper(label);
        },

        onInputClick(chunkIndex, labelIndex) {
            const index = this.chunkSize * chunkIndex + labelIndex;

            this.$emit('toggle-display', index);
        }
    }
};
</script>
