import axios from 'axios';
import moment from 'moment';
import 'moment-timezone';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index() {
        const { data } = await axios.get(`/api/settings`);

        return data;
    },

    async update(vuexContext, { tag, settings }) {
        const { data } = await axios.post('/api/settings', { tag, settings });

        return data;
    },

    async setDefaultTimezone() {
        const { data: timezoneSetting } = await axios.get(
            `/api/settings/public/timezone`
        );

        moment.tz.setDefault(timezoneSetting.value);
    }
};
