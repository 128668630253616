import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index(vuexContext, listName) {
        const { data } = await axios.get(`/api/list-products/${listName}`);

        return data;
    },

    async store(vuexContext, { productId, listName }) {
        const { data } = await axios.post(`/api/list-products`, { productId, listName });

        return data;
    },

    async update(vuexContext, listProduct) {
        const { data } = await axios.put(
            `/api/list-products/${listProduct.id}`,
            listProduct
        );

        return data;
    },

    delete(vuexContext, id) {
        return axios.delete(`/api/list-products/${id}`);
    },

    async changeOrder(vuexContext, items) {
        const { data } = await axios.post('/api/list-products/order', {
            items
        });

        return data;
    }
};
