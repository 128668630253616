<template>
    <div id="wrapper">
        <nav-bar />
        <side-bar />

        <div class="content-page">
            <div class="content">
                <div class="container-fluid">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import NavBar from '@components/NavBar';
import SideBar from '@components/SideBar';

export default {
    components: {
        NavBar,
        SideBar
    },

    created() {
        document.body.classList.remove('authentication-bg');
        document.body.classList.remove('authentication-bg-pattern');

        this.initMenu();
    },

    methods: {
        ...mapActions({
            initMenu: 'layout/initMenu'
        })
    }
};
</script>
