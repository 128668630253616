import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index(
        vuexContext,
        {
            currentPage = 1,
            perPage = 100,
            search = '',
            sortBy = 'created_at',
            descending = true,
            filters = null
        } = {}
    ) {
        const { data } = await axios.get('/api/secondary-products', {
            params: {
                page: currentPage,
                perPage,
                q: search,
                sortBy,
                order: descending ? 'DESC' : 'ASC',
                filters: JSON.stringify(filters)
            }
        });

        return data;
    },

    async show(vuexContext, productId) {
        const { data } = await axios.get(
            `/api/secondary-products/${productId}`
        );

        return data;
    },

    async store(vuexContext, { product }) {
        const { data } = await axios.post(`/api/secondary-products`, product);

        return data;
    },

    async update(vuexContext, { id, product }) {
        const { data } = await axios.put(
            `/api/secondary-products/${id}`,
            product
        );

        return data;
    },

    async delete(vuexContext, id) {
        await axios.delete(`/api/secondary-products/${id}`);
    }
};
