import Vue from 'vue';
import Vuelidate from 'vuelidate';
import BootstrapVue from 'bootstrap-vue';
import { url as appUrl } from '@/config';

Vue.use(BootstrapVue);
Vue.use(Vuelidate);

Vue.prototype.$toaster = function(message, options = {}) {
    this.$bvToast.toast(message, {
        title: 'Success!',
        variant: 'success',
        solid: true,
        ...options
    });
};

Vue.prototype.$toasterError = function(message = null) {
    this.$bvToast.toast(message || 'Something went wrong', {
        title: 'Error!',
        variant: 'danger',
        solid: true
    });
};

Vue.prototype.$toasterWarning = function(message) {
    this.$bvToast.toast(message, {
        title: 'Warning!',
        variant: 'warning',
        solid: true
    });
};

Vue.prototype.$downloadFile = (href, name, blank = false) => {
    const fileLink = document.createElement('a');

    fileLink.href = href;
    fileLink.setAttribute('download', name);

    if (blank) {
        fileLink.setAttribute('target', '_blank');
    }

    fileLink.click();
};

Vue.prototype.$confirmationModal = async function(message, options = {}) {
    try {
        const confirmed = await this.$bvModal.msgBoxConfirm(message, {
            title: 'Please Confirm',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'danger',
            footerClass: 'col-12 d-flex justify-content-between',
            hideHeaderClose: false,
            centered: true,
            ...options
        });

        return confirmed;
    } catch (err) {
        console.error(err);
    }
};

Vue.prototype.$okModal = async function(message, options = {}) {
    this.$bvModal.msgBoxOk(message, {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        footerClass: 'col-12 d-flex',
        hideHeaderClose: false,
        centered: true,
        ...options
    });
};

Vue.prototype.$openNewTab = function(path) {
    if (path.startsWith('/')) {
        window.open(`${appUrl}${path}`, '_blank');

        return;
    }

    window.open(path, '_blank');
};
