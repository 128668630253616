import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index() {
        const { data } = await axios.get(`/api/mails`);

        return data;
    },

    async show(vuexContext, id) {
        const { data } = await axios.get(`/api/mails/${id}`);

        return data;
    },

    async update(vuexContext, template) {
        const { data } = await axios.put(
            `/api/mails/${template.id}`,
            template
        );

        return data;
    },

    async getVariables(vuexContext, code) {
        const { data } = await axios.get(`/api/mails/variables/${code}`);

        return data;
    }
};
