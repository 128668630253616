<template>
    <div class="navbar-custom">
        <ul class="list-unstyled topnav-menu float-right mb-0">
            <b-nav-item-dropdown
                right
                class="notification-list"
                menu-class="profile-dropdown"
            >
                <template slot="button-content">
                    <div class="nav-user mr-0 waves-effect waves-light">
                        <img
                            src="@assets/images/users/default.jpg"
                            alt="user-image"
                            class="rounded-circle"
                        />
                        <span class="pro-user-name ml-1">
                            {{ loggedUser ? `${loggedUser.first_name} ${loggedUser.last_name}` : '' }}
                            <i class="mdi mdi-chevron-down" />
                        </span>
                    </div>
                </template>
                <b-dropdown-item to="/my-account">
                    <i class="fe-user mr-1" />
                    <span>
                        My Account
                    </span>
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item to="/logout">
                    <i class="fe-log-out mr-1" />
                    <span>
                        Logout
                    </span>
                </b-dropdown-item>
            </b-nav-item-dropdown>
        </ul>

        <div class="logo-box">
            <router-link to="/" class="logo text-center">
                <span class="logo-lg">
                    <img src="@assets/images/logo.svg" alt="" height="35px" />
                </span>
                <span class="logo-sm">
                    <img src="@assets/images/logo.svg" alt="" height="20px" />
                </span>
            </router-link>
        </div>

        <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
            <li>
                <button
                    class="button-menu-mobile waves-effect waves-light"
                    @click="toggleMenuMobile"
                >
                    <i class="fe-menu" />
                </button>
                <button
                    class="button-menu-toggle waves-effect waves-light"
                    @click="toggleMenu"
                >
                    <i v-if="isMenuCollapsed" class="fe-chevron-right" />
                    <i v-else class="fe-chevron-left" />
                </button>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            loggedUser: 'auth/loggedUser',
            isMenuCollapsed: 'layout/isMenuCollapsed'
        })
    },

    methods: {
        ...mapActions({
            toggleMenu: 'layout/toggleMenu'
        }),

        toggleMenuMobile() {
            document.body.classList.toggle('sidebar-enable');
            document.body.classList.remove('enlarged');
        }
    }
};
</script>

<style lang="scss">
.noti-scroll {
    height: 220px;
}
.ps > .ps__scrollbar-y-rail {
    width: 8px !important;
    background-color: transparent !important;
}
.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
    width: 6px !important;
}
.button-menu-mobile {
    outline: none !important;
}
</style>
