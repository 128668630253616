<template>
    <b-alert
        :show="shouldUpdate"
        class="fixed-bottom m-2"
        dismissible
        fade
        variant="warning"
    >
        New version of the app is available. Click
        <a href="#" class="alert-link" @click="hardReload">here</a> to reload.
    </b-alert>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            shouldUpdate: 'versions/getShouldUpdate'
        })
    },

    methods: {
        hardReload() {
            window.location.reload(true);
        }
    }
};
</script>
