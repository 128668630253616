<template>
    <div id="sidebar-menu">
        <ul id="side-menu" class="metismenu">
            <template v-for="(item, itemIndex) of items">
                <li
                    v-if="item.isMenuTitle"
                    :key="itemIndex"
                    class="menu-title"
                >
                    {{ item.text }}
                </li>

                <li
                    v-else-if="hasUserPermissions(item.permission) && !item.hasSecondaryMenu"
                    :key="itemIndex"
                >
                    <router-link :to="item.url">
                        <i
                            :class="item.icon"
                            :style="{
                                'font-size': item.iconSize
                            }"
                        />
                        <span>{{ item.text }}</span>
                    </router-link>
                </li>

                <li
                    v-else-if="hasUserPermissions(item.permission)"
                    :key="itemIndex"
                    class="has-dropdown"
                    :class="{
                        'active mm-active': isSubActive(item.secondaryItems)
                    }"
                >
                    <a
                        aria-expanded="false"
                        class="waves-effect"
                        :class="{
                            active: isSubActive(item.secondaryItems)
                        }"
                    >
                        <i
                            :class="item.icon"
                            :style="{
                                'font-size': item.iconSize
                            }"
                        />
                        <span>{{ item.text }}</span>
                        <span class="menu-arrow" />
                    </a>
                    <ul
                        aria-expanded="false"
                        class="nav-second-level"
                        :class="{
                            in: isSubActive(item.secondaryItems) && !isMenuCollapsed,
                            collapse: isMobile
                        }"
                    >
                        <template v-for="(subItem, sIndex) in item.secondaryItems">
                            <li
                                v-if="!subItem.hasTertiaryMenu"
                                :key="sIndex"
                                :class="{ active: isSubActive(subItem)}"
                            >
                                <router-link :to="subItem.url">
                                    {{ subItem.text }}
                                </router-link>
                            </li>

                            <li
                                v-else
                                :key="sIndex"
                                :class="{ active: isSubActive(subItem)}"
                            >
                                <a
                                    aria-expanded="false"
                                    class="waves-effect"
                                    :class="{
                                        active: isSubActive(subItem)
                                    }"
                                >
                                    <i class="fas fa-list" />
                                    {{ subItem.text }}
                                </a>
                                <ul
                                    aria-expanded="false"
                                    class="nav-third-level nav"
                                    :class="{
                                        in: isSubActive(subItem)
                                    }"
                                >
                                    <li
                                        v-for="(tertiaryItem, tIndex) in subItem.tertiaryItems"
                                        :key="tIndex"
                                        :class="{
                                            active: isSubActive(tertiaryItem)
                                        }"
                                    >
                                        <router-link :to="tertiaryItem.url">
                                            {{ tertiaryItem.text }}
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                        </template>
                    </ul>
                </li>
            </template>
        </ul>
        <div class="clearfix" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MetisMenu from 'metismenujs/dist/metismenujs';

export default {
    data: () => ({
        items: [
            {
                text: 'Navigation',
                isMenuTitle: true
            },
            {
                text: 'Users',
                url: '/users',
                icon: 'fe-users',
                permission: 'users'
            },
            {
                text: 'Customers',
                url: '/customers',
                icon: 'fas fa-user-tag',
                permission: 'customers'
            },
            {
                text: 'Products',
                icon: 'fe-package',
                permission: 'products',
                hasSecondaryMenu: true,
                secondaryItems: [
                    {
                        text: 'Products List',
                        url: '/products'
                    },
                    {
                        text: 'Bundles',
                        url: '/bundles'
                    },
                    {
                        text: 'Attributes',
                        url: '/attributes'
                    }
                ]
            },
            {
                text: 'Orders',
                url: '/orders',
                icon: 'fas fa-file-invoice-dollar',
                permission: 'orders'
            },
            {
                text: 'Factory Data',
                url: '/factory-data',
                icon: 'mdi mdi-factory',
                iconSize: '1.3rem',
                permission: 'factory_data'
            },
            {
                text: 'Logistics Center',
                url: '/logistics-center',
                icon: 'mdi mdi-truck',
                iconSize: '1.3rem',
                permission: 'logistics_center'
            },
            {
                text: 'Fedex',
                icon: 'mdi mdi-alpha-f-box',
                iconSize: '1.4rem',
                permission: 'batches',
                hasSecondaryMenu: true,
                secondaryItems: [
                    {
                        text: 'Batches',
                        url: '/fedex-batches'
                    },
                    {
                        text: 'Queues',
                        url: '/fedex-queues'
                    }
                ]
            },
            {
                text: 'Warehouses',
                url: '/warehouses',
                icon: 'fas fa-warehouse',
                iconSize: '0.9rem',
                permission: 'warehouses'
            },
            {
                text: 'App Orders',
                url: '/app-orders',
                icon: 'fas fa-mobile-alt',
                permission: 'orders'
            },
            {
                text: 'Promo Codes',
                url: '/promo-codes',
                icon: 'fas fa-percent',
                permission: 'promo_codes'
            },
            {
                text: 'Gift Cards',
                url: '/gift-cards',
                icon: 'fe-gift',
                permission: 'gift_cards'
            },
            {
                text: 'Free Gift',
                url: '/free-gift',
                icon: 'fas fa-gifts',
                permission: 'free_gift'
            },
            {
                text: 'Questionnaires',
                url: '/questionnaires',
                icon: 'fas fa-question',
                permission: 'questionnaires'
            },
            {
                text: 'Articles',
                url: '/articles',
                icon: 'fas fa-book',
                permission: 'articles'
            },
            {
                text: 'Exercises',
                url: '/exercises',
                icon: 'fas fa-running',
                permission: 'exercises'
            },
            {
                text: 'Mailing',
                url: '/mailing',
                icon: 'fas fa-mail-bulk',
                permission: 'mailing'
            },
            {
                text: 'Media Management',
                url: '/media',
                icon: 'fas fa-images',
                permission: 'media_management'
            },
            {
                text: 'Contents',
                icon: 'fe-box',
                permission: 'contents',
                hasSecondaryMenu: true,
                secondaryItems: [
                    {
                        text: 'Mobile',
                        hasTertiaryMenu: true,
                        tertiaryItems: [
                            {
                                text: 'Tutorial Page',
                                url: '/contents/tutorial-page'
                            }
                        ]
                    },
                    {
                        text: 'General Components',
                        hasTertiaryMenu: true,
                        tertiaryItems: [
                            {
                                text: 'FAQ',
                                url: '/contents/faq'
                            },
                            {
                                text: 'Follow Us',
                                url: '/contents/follow-us'
                            },
                            {
                                text: 'Footer',
                                url: '/contents/footer'
                            },
                            {
                                text: 'Header',
                                url: '/contents/header'
                            },
                            {
                                text: 'Menu Main',
                                url: '/contents/menu-main'
                            },
                            {
                                text: 'Menu Mobile',
                                url: '/contents/menu-mobile'
                            },
                            {
                                text: 'Menu Top',
                                url: '/contents/menu-top'
                            },
                            {
                                text: 'Newsletter Modal',
                                url: '/contents/newsletter-modal'
                            },
                            {
                                text: 'Notice',
                                url: '/contents/notice'
                            },
                            {
                                text: 'Pages Titles',
                                url: '/contents/pages-titles'
                            },
                            {
                                text: 'Promotion Slider',
                                url: '/contents/promotion-slider'
                            },
                            {
                                text: 'Version Alert',
                                url: '/contents/version-alert'
                            }
                        ]
                    },
                    {
                        text: 'Homepage',
                        hasTertiaryMenu: true,
                        tertiaryItems: [
                            {
                                text: 'Bottom Scroll Text',
                                url: '/contents/bottom-home-page-text'
                            },
                            {
                                text: 'Guarantee',
                                url: '/contents/guarantee'
                            },
                            {
                                text: 'FAQ',
                                url: '/contents/homepage-faq'
                            },
                            {
                                text: 'How It Works',
                                url: '/contents/homepage-how-it-works'
                            },
                            {
                                text: 'Process',
                                url: '/contents/process-upstep'
                            },
                            {
                                text: 'Product Suggestion',
                                url: '/contents/product-suggestion'
                            },
                            {
                                text: 'Questionnaire',
                                url: '/contents/homepage-questionnaire'
                            },
                            {
                                text: 'Timer',
                                url: '/contents/timer'
                            },
                            {
                                text: 'Walking With a Smile',
                                url: '/contents/walking-with-a-smile'
                            },
                            {
                                text: 'Why Upstep',
                                url: '/contents/why-upstep'
                            },
                            {
                                text: 'Versions',
                                url: '/contents/versions'
                            }
                        ]
                    },
                    {
                        text: 'Integrations',
                        hasTertiaryMenu: true,
                        tertiaryItems: [
                            {
                                text: 'Fedex',
                                url: '/contents/fedex'
                            }
                        ]
                    },
                    {
                        text: 'About',
                        url: '/contents/about'
                    },
                    {
                        text: 'Accounts Page',
                        url: '/contents/accounts-page'
                    },
                    {
                        text: 'Blog',
                        url: '/contents/blog'
                    },
                    {
                        text: 'Cart',
                        url: '/contents/cart'
                    },
                    {
                        text: 'Checkout',
                        url: '/contents/checkout'
                    },
                    {
                        text: 'Exercises',
                        url: '/contents/exercises'
                    },
                    {
                        text: 'Gift Card',
                        url: '/contents/gift-card'
                    },
                    {
                        text: 'Help',
                        url: '/contents/help'
                    },
                    {
                        text: 'How It Works',
                        url: '/contents/how-it-works'
                    },
                    {
                        text: 'Impression Kit',
                        url: '/contents/impression-kit'
                    },
                    {
                        text: 'One Time Offer',
                        url: '/contents/one-time-offer'
                    },
                    {
                        text: 'Order Tracking',
                        url: '/contents/order-tracking'
                    },
                    {
                        text: 'Post Purchase',
                        url: '/contents/post-purchase'
                    },
                    {
                        text: 'Privacy Policy',
                        url: '/contents/privacy-policy'
                    },
                    {
                        text: 'Product Page',
                        url: '/contents/product-page'
                    },
                    {
                        text: 'Reorder',
                        url: '/contents/reorder'
                    },
                    {
                        text: 'Reviews',
                        url: '/contents/reviews'
                    },
                    {
                        text: 'Shop',
                        url: '/contents/shop'
                    },
                    {
                        text: 'Terms and Conditions',
                        url: '/contents/terms-and-conditions'
                    },
                    {
                        text: 'Thank You',
                        url: '/contents/thank-you'
                    },
                    {
                        text: 'Upsell',
                        url: '/contents/upsell'
                    }
                ],
            },
            {
                text: 'Contents Mobile 3D',
                icon: 'fe-box',
                permission: 'contents_mobile_3d',
                hasSecondaryMenu: true,
                secondaryItems: [
                    {
                        text: 'Fluent Flow',
                        url: '/contents-m/mobile-3d-fluent-flow'
                    },
                    {
                        text: 'Guidance',
                        url: '/contents-m/mobile-3d-guidance'
                    },
                    {
                        text: 'Login',
                        url: '/contents-m/mobile-3d-login'
                    },
                    {
                        text: 'Manually Flow',
                        url: '/contents-m/mobile-3d-manually-flow'
                    },
                    {
                        text: 'Photo Stage',
                        url: '/contents-m/mobile-3d-photo-stage'
                    },
                    {
                        text: 'Preview',
                        url: '/contents-m/mobile-3d-preview'
                    },
                    {
                        text: 'Scanning Tips',
                        url: '/contents-m/mobile-3d-scanning-tips'
                    },
                    {
                        text: 'Scans Scale',
                        url: '/contents-m/mobile-3d-scans-scale'
                    },
                    {
                        text: 'Success',
                        url: '/contents-m/mobile-3d-success'
                    },
                    {
                        text: 'Voice Over',
                        url: '/contents-m/mobile-3d-voice-over'
                    }
                ]
            },
            {
                text: 'Tracking Statuses',
                url: '/tracking-statuses',
                icon: 'fas fa-map-marker-alt',
                permission: 'tracking_statuses'
            },
            {
                text: 'Timelines',
                url: '/timelines',
                icon: 'fas fa-stream',
                permission: 'timelines'
            },
            {
                text: 'Api Keys',
                url: '/api-keys',
                icon: 'fas fa-key',
                permission: 'api_keys'
            },
            {
                text: 'Barcode Scanner',
                url: '/barcode-scanner',
                icon: 'mdi mdi-barcode-scan',
                iconSize: '1.3rem',
                permission: 'barcode_scanner'
            },
            {
                text: 'Settings',
                url: '/settings',
                icon: 'fas fa-cog',
                permission: 'settings'
            },
            {
                text: 'Factory Spain',
                isMenuTitle: true
            },
            {
                text: 'Instructions 3D',
                url: '/es-factory-data',
                icon: ' fas fa-file-alt'
            }
        ]
    }),

    computed: {
        ...mapGetters({
            user: 'auth/loggedUser',
            getAvailablePermissions: 'roles/getAvailablePermissions',
            isMenuCollapsed: 'layout/isMenuCollapsed'
        }),

        isMobile() {
            return document.body.classList.value.includes('enlarged');
        },

        userPermissions() {
            if (!this.user || !this.user.acl) {
                return [];
            }

            const {
                acl: { permissions }
            } = this.user;

            if (permissions.all) {
                const allPermissions = {};

                this.getAvailablePermissions.forEach(permission => {
                    allPermissions[permission] = true;
                });

                return allPermissions;
            }

            return permissions;
        }
    },

    mounted() {
        new MetisMenu('#side-menu');
    },

    methods: {
        isSubActive(secondaryItems) {
            const secondaryItemsArray = Array.isArray(secondaryItems) ? secondaryItems : [secondaryItems];

            const flatItems = secondaryItemsArray.flatMap(subItem => subItem.tertiaryItems || subItem);

            const paths = flatItems.map(subItem => subItem.url);

            return paths.some(path => this.$route.fullPath.startsWith(path));
        },

        hasUserPermissions(permission) {
            if (!permission) {
                return true;
            }

            return this.userPermissions[permission];
        }
    }
};
</script>

<style lang="scss">
@import '~metismenujs/scss/metismenujs';
</style>
