const initialState = () => {
    return {
        cmsVersion: localStorage.getItem('cmsVersion')
            ? JSON.parse(localStorage.getItem('cmsVersion'))
            : '',
        shouldUpdate: false
    };
};

export const state = () => initialState();

export const getters = {
    getCmsVersion: state => state.cmsVersion,

    getShouldUpdate: state => state.shouldUpdate
};

export const mutations = {
    SET_CMS_VERSION: (state, version) => {
        state.cmsVersion = version;
        localStorage.setItem('cmsVersion', JSON.stringify(version));
    },

    SET_SHOULD_UPDATE: (state, payload) => {
        state.shouldUpdate = payload;
    }
};

export const actions = {};
