import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async getByCategory(vuexContext, category) {
        const { data } = await axios.get(
            `/api/shop-products/category/${category}`
        );

        return data;
    },

    async store(vuexContext, shopProduct) {
        const { data } = await axios.post(`/api/shop-products`, shopProduct);

        return data;
    },

    async delete(vuexContext, id) {
        return axios.delete(`/api/shop-products/${id}`);
    },

    async changeOrder(vuexContext, items) {
        const { data } = await axios.post(`/api/shop-products/order`, {
            items
        });

        return data;
    }
};
